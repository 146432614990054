import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { AccountService } from 'app/services/AccountService';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  userEmail;
  error: string;

  constructor(private router: Router, private toastr: ToastrService, private accountService: AccountService) { }

  ngOnInit() { }

  submitEmail() {
    this.submitButton.disabled = true;
    this.progressBar.mode = 'indeterminate';
    this.accountService.forgotPassword(this.userEmail)
      .pipe(first())
      .subscribe(
        data => {
          this.toastr.info(data.result);
          this.router.navigate(["/reset-password", this.userEmail]);
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          this.progressBar.mode = 'determinate';
        });
  }
}

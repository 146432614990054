import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vaccines',
  templateUrl: './vaccines.component.html',
  styleUrls: ['./vaccines.component.scss']
})
export class VaccinesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

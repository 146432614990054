import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: string[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  tooltip?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor() { }

  plainMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "/dashboard",
      roles: ['DigitalOfficer', 'SuperAdmin', 'Doctor']
    },
    //  {
    //   name: "India Status",
    //   type: "link",
    //   tooltip: "India Status",
    //   icon: "message",
    //   state: "/corona-stat/india"
    //   // state: "/users"
    // },
    // {
    //   name: "Malawi Corona Status",
    //   type: "link",
    //   tooltip: "India Status",
    //   icon: "donut_small",
    //   state: "malawicoronstatus"
    //   // state: "/users"
    // },
    {
      name: "World Covid Status",
      type: "link",
      tooltip: "Messages",
      icon: "language",
      state: "/world-stat",
      roles: ['SuperAdmin']
      // state: "/users"
    },
    //  {
    //     name: "user tracking report ",
    //    type: "link",
    //    tooltip: "messages",
    //    icon: "explore",
    //     state: "/travel_history",

    //  } , 
    {
      name: "Tracking History ",
      type: "link",
      tooltip: "messages",
      icon: "explore",
      state: "/tracking-history",
      roles: ['DigitalOfficer', 'SuperAdmin', 'Doctor']

    },
    //    {
    //     name: "User Tracking History ",
    //    type: "link",
    //    tooltip: "messages",
    //    icon: "explore",
    //     state: "/tracking-userhistory",

    //  } , 
    //     {
    //   name: "Add Symptom Checker",
    //   type: "link",
    //   tooltip: "Add Symptom Checker",
    //   icon: "language",
    //   state: "/add-symptomchecker",
    //   roles: ['SuperAdmin']
    // },
    {
      name: "Symptom Checker Score",
      type: "link",
      tooltip: "Symptom",
      icon: "cloud_done",
      state: "/symtoms_score",
      roles: ['DigitalOfficer', 'SuperAdmin', 'Doctor']
      // sub: [
      //   { name: "Doctors", state: "doctors" },        
      //   { name: "Medical Officers", state: "medical-officers" },
      // ]
    },
    {
      name: "Manage Users",
      type: "link",
      tooltip: "Patients",
      icon: "people",
      state: "/User",
      roles: ['SuperAdmin']

      //  sub: [
      //    { name: "Patients", state: "patients" },      
      // ]
    },

    {
      name: "Key Performance Indicator",
      type: "dropDown",
      tooltip: "Key Performance Indicator",
      icon: "summarize",
      roles: ['SuperAdmin', 'DigitalOfficer','Doctor'],
      sub: [
        {
          name: "Positivity Rate ",
          type: "link",
          tooltip: "Positivity Rate",
          icon: "crop_rotate",
          state: "/regionofincident"
        },
        {
          name: "Case Fatality Rate ",
          type: "link",
          tooltip: "Case Fatality Rate",
          icon: "announcement",
          state: "/hotspotarea"          
        },
        {
          name: "Quarantine Compliance Monitoring",
          type: "link",
          tooltip: "Quarantine Compliance Monitoring",
          icon: "track_changes",
          state: "/lockdown-movement-report"
        },
        {
          name: "Resources Allocation & Planning",
          type: "link",
          tooltip: "Resources Allocation & Planning",
          icon: "healing",
          state: "/resourcesofallocation"
        },
        {
          name: "Vaccine Tracker ",
          type: "link",
          tooltip: "Vaccine Progress ",
          icon: "published_with_changes",
          state: "/vaccineprogress",
        },
        
      ]

    },


    {
      name: "Patients",
      type: "link",
      tooltip: "Patients",
      icon: "accessibility",
      state: "/managepatient-list",
      roles: ['Doctor', 'DigitalOfficer']
      //  sub: [
      //    { name: "Patients", state: "patients" },      
      // ]
    },

    {
      name: "Change Password",
      type: "link",
      tooltip: "Patients",
      icon: "build",
      state: "/change-password",
      roles: ['SuperAdmin', 'DigitalOfficer', 'Doctor']
      // sub: [
      //   { name: "Submitted SuperBills", state: "submitted-superbills" },
      //   { name: "Processed SuperBills", state: "processed-superbills" },
      // ]

    },
   
    {
      name: "Sign Out",
      type: "link",
      tooltip: "Sign Out",
      icon: "exit_to_app",
      state: "../signin",
      roles: ['SuperAdmin', 'DigitalOfficer', 'Doctor']
    },






    

    //  {
    //    name: "volunteers",
    //    type: "dropdown",
    //    tooltip: "addpatient",
    //   icon: "receipt",
    //   state: "#",

    //  },

    // {
    //   name: "interaction list",
    //   type: "link",
    //   tooltip: "messages",
    //   icon: "message",
    //   state: "/interaction_list"

    // }, 

  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();


  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.

  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case "separator-menu":
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case "icon-menu":
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
  }
}

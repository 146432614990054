import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { AccountService } from 'app/services/AccountService';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  userEmail;
  passwordForm: FormGroup
  error: string;


  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private accountService: AccountService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.userEmail = params.get('userEmail');
    });
    const password = new FormControl('', Validators.required);
    const confirmPassword = new FormControl('', CustomValidators.equalTo(password));

    this.passwordForm = new FormGroup({
      otp: new FormControl('', [Validators.required]),
      password: password,
      confirmPassword: confirmPassword
    });
  }

  submitPassword() {
    const passwordData = this.passwordForm.value;
    console.log(passwordData);

    this.submitButton.disabled = true;
    this.progressBar.mode = 'indeterminate';
    this.accountService.resetPassword(this.userEmail, passwordData.password, passwordData.confirmPassword, passwordData.otp)
      .subscribe(
        data => {
          this.toastr.info(data.result);
          this.router.navigate(["/signin"]);
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          this.progressBar.mode = 'determinate';
        });
  }
}

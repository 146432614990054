import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogButtonType, DialogType } from 'app/helpers/app-enums';
import { ConfirmationDialogData } from './confirmation-dialog-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  okButtonText = "Ok";
  cancelButtonText = "Cancel";
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
    data.Title = data.Title ? data.Title : 'Confirmation';
    data.DialogType = data.DialogType ? data.DialogType : DialogType.Confirmation;
    data.ButtonType = data.ButtonType ? data.ButtonType : DialogButtonType.OkCancel;
  }

  ngOnInit() {
    this.manageButtonsText();
  }
  manageButtonsText() {
    if (this.data.ButtonType == DialogButtonType.YesNo) {
      this.okButtonText = "Yes";
      this.cancelButtonText = "No";
    }
  }
  onAction(action: string): void {
    this.dialogRef.close(action);
  }

}

import { Component, OnInit,AfterViewInit,  ElementRef, ViewChild  } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: 'app-corona-affectedarea',
  templateUrl: './corona-affectedarea.component.html',
  styleUrls: ['./corona-affectedarea.component.scss']
})
export class CoronaAffectedareaComponent implements OnInit {
  debugger;
  
  public barChart: GoogleChartInterface = {
   
    chartType: 'BarChart',
   
     dataTable: [
       ['Number of Death in %','Number of Death %'],
       ['Week1', 48],
       ['Week2', 50],
      ['Week3', 41],
       ['Week4',  40],
      ['Week5', 2],
       ['Week6',77],
       ['Week7',68]
     ],
   
    options: {'title': '','height':400},
  };
  
 
  
  
  constructor() { }

  ngOnInit() {
    
  }

}

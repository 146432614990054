import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'app/services/UserService';
import { ToastrService } from 'ngx-toastr';
import { CoronapatienttravellhistoryService } from 'app/services/coronapatienttravellhistoryService';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material';
import { GooglemapComponent } from '../googlemap/googlemap.component';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ExportModel } from 'app/models/export.model';
import { ExportService } from 'app/services/export.service';

@Component({
  selector: 'app-tracking-history',
  templateUrl: './tracking-history.component.html',
  styleUrls: ['./tracking-history.component.scss']
})

export class TrackingHistoryComponent implements OnInit {


  userNameChanged: Subject<string> = new Subject<string>();
  data: [];
  pdfData: any;
  pdfDoc = new jsPDF();
  isEnable: boolean = false;
  userId: any;
  userName = '';
  fromDate = '';
  toDate = '';
  locationPickFrom = '';
  mobileNumber = ''
  exportModel: ExportModel = new ExportModel();
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
    orderBy: 'FirstName',
    orderDir: 'asc'
  };
  constructor(private route: ActivatedRoute,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private exportService: ExportService,
    private coronapatienttravellhistoryService: CoronapatienttravellhistoryService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog) { }
  btnClick = function () {
    this.router.navigateByUrl('/User');
  };
  ngOnInit() {
    if (this.route.snapshot.paramMap.get('userId')) {
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    this.getItems();

    this.userNameChanged
      .debounceTime(300) // wait 300ms after the last event before emitting last event
      .distinctUntilChanged() // only emit if value is different from previous value
      .subscribe(model => this.userName = model);
  }
  clear() {
    debugger;
    this.userName = '';
    this.fromDate = '';
    this.toDate = '';
    this.locationPickFrom = '';
    this.mobileNumber = '';
    this.page = {
      pageNumber: 0,
      itemCount: 0,
      pageSize: 10,
      orderBy: '',
      orderDir: ''
    };
    this.filterItems();
    this.getItems();
  }


  onPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getItems();
  }
  // For Sorting
  onSort(sortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getItems();
  }
  //Filter Items
  filterItems() {
    //this.userNameChanged.next(text);
    this.getItems();
  }



  /**
   * You will render the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  getItems(isForPDF = false) {
    let fromD = this.fromDate ? this.datePipe.transform(this.fromDate, 'MM/dd/yyyy') : '',
      toD = this.toDate ? this.datePipe.transform(this.toDate, 'MM/dd/yyyy') : '';
    const params = new HttpParams()
      .set('sortName', `${this.page.orderBy}`)
      .set('sortDirection', `${this.page.orderDir}`)
      .set('pageIndex', `${isForPDF ? 1 : this.page.pageNumber + 1}`)
      .set('pageSize', `${isForPDF ? 10000000 : this.page.pageSize}`)
      .set('userId', `${this.userId ? this.userId : 0}`)
      .set('locationPickFrom', `${this.locationPickFrom ? this.locationPickFrom : 0}`)
      .set('fromDate', `${fromD}`)
      .set('toDate', `${toD}`)
      .set('userName', `${this.userName}`)
      .set('mobileNumber', `${this.mobileNumber}`);
    this.coronapatienttravellhistoryService.GetAllUserTrackingHistory(params)
      .subscribe(data => {
        if (!isForPDF) {
          this.data = data.result.data;
        }
        else {
          this.pdfData = data.result.data;
          this.exportToPDFCallback();
        }
        this.page.itemCount = data.result.itemCount;
        this.cdr.markForCheck();
      },


        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }

  showMap(lat, long) {
    const dialogRef = this.dialog.open(GooglemapComponent, {
      data: {
        lat: lat,
        long: long
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  exportToPDF() {
    this.getItems(true);
  }
  exportToPDFCallback() {
    this.exportModel.reportTitle = "Tracking History";
    let data=this.pdfData.map(x => ({ ...x,createdOn: this.datePipe.transform(x["createdOn"], 'MM/dd/yyyy')}));
    this.exportModel.json=data;
    this.exportModel.columnNames = [
      { header: 'User Name', dataKey: 'userFullName' },
      { header: 'State', dataKey: 'state' },
      { header: 'City', dataKey: 'city' },
      { header: 'Zip Code', dataKey: 'zipCode' },
      { header: 'Created On', dataKey: 'createdOn' },
      { header: 'Location Type', dataKey: 'locationPickFromStr' }
    ];
    this.exportService.export(this.exportModel);
  }
}

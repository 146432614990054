import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { UserService } from 'app/services/UserService';
import {CoronapatienttravellhistoryService} from 'app/services/coronapatienttravellhistoryService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patienttravellhistory',
  templateUrl: './patienttravellhistory.component.html',
  styleUrls: ['./patienttravellhistory.component.scss'],
  animations: egretAnimations
})
export class PatienttravellhistoryComponent implements OnInit {
  // travel_history: any;
  travel_history: any[];
  temp: any;

  constructor(private toastr: ToastrService,private coronapatienttravellhistoryService: CoronapatienttravellhistoryService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.getItems();
  }
  getItems() {

    this.coronapatienttravellhistoryService.gettravelhistory()
      .subscribe(data => {             
        this.travel_history = data.travel_history;        
         console.log(this.travel_history);
        this.cdr.markForCheck();
        },            
       
    
      error => {
        this.toastr.error(error.error.responseException.exceptionMessage);
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/AuthService';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router,private authService: AuthService) { }

  ngOnInit() {
    window.scroll(0,0)
    if(this.authService.isLoggedIn()){
      this.router.navigate(['/dashboard']);
      
    }
  }

}

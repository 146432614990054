import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { filter, first } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SymptomchekerService } from 'app/services/symptomchekerService';
import { FormControl } from '@angular/forms';
import { Data } from 'app/data';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HELPERS } from 'app/helpers/helpers';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-symptomscheker',
  templateUrl: './symptomscheker.component.html',
  styleUrls: ['./symptomscheker.component.scss'],
  animations: egretAnimations,
})

export class SymptomschekerComponent implements OnInit {
  userNameChanged: Subject<string> = new Subject<string>();
  result: any[];
  temp: any;
  state: any[];
  userId: any;
  userName = '';
  fromDate = '';
  toDate = '';
  score = '';
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
    orderBy: '',
    orderDir: ''
  };


  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private symptomchekerService: SymptomchekerService, private toastr: ToastrService) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('userId')) {
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    this.getItems();
  }

  clear() {
    this.userName = '';
    this.fromDate = '';
    this.toDate = '';
    this.score = '';
    this.page = {
      pageNumber: 0,
      itemCount: 0,
      pageSize: 10,
      orderBy: '',
      orderDir: ''
    };
    this.filterItems();
    this.getItems();
  }

  onPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getItems();
  }

  onSort(sortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getItems();
  }

  filterItems() {
    this.getItems();
  }

  getItems() {
    debugger;
    let fromD = this.fromDate ? this.datePipe.transform(this.fromDate, 'MM/dd/yyyy') : '',
     toD = this.toDate ? this.datePipe.transform(this.toDate, 'MM/dd/yyyy') : '';
    const params = new HttpParams()
      .set('sortName', `${this.page.orderBy}`)
      .set('sortDirection', `${this.page.orderDir}`)
      .set('pageIndex', `${this.page.pageNumber + 1}`)
      .set('pageSize', `${this.page.pageSize}`)
      .set('userId', `${this.userId ? this.userId : 0}`)
      .set('userName', `${this.userName}`)
      .set('fromDate', `${fromD}`)
      .set('toDate', `${toD}`)
      .set('score', `${this.score ? this.score : -1}`);
    debugger;

    this.symptomchekerService.getAll(params)
      .subscribe(data => {
        debugger;
        this.result = data.result.data;
        this.page.itemCount = data.result.itemCount;
        this.cdr.markForCheck();
      },
        error => {
          debugger;
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }


}

import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: 'app-hot-spot-area',
  templateUrl: './hot-spot-area.component.html',
  styleUrls: ['./hot-spot-area.component.scss']
})
export class HotSpotAreaComponent implements OnInit {
  selectedCity = 'New Jersey';
  regionList: any;
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
  };
  barChart: any;
  barChartAlbama:any;
  barChartNewyork:any;
  constructor() {

  }

  ngOnInit() {
    this.getRegionofIncidentList();
    this.bindChartData();
    window.scroll(0,0)
  }
  bindChartData() {
    this.barChart =null;
    if (this.selectedCity == "New Jersey") {
      this.barChart = {
        chartType: 'ColumnChart',
        dataTable: [
          ['Week1', 'Positive Cases','Deaths'],
          ['Week1', 25000, 12000],
          ['Week2', 20000, 10000],
          ['Week3', 12022, 5000],
          ['Week4',  30000, 12034],
          ['Week5', 50000, 1234],
          ['Week6',40000, 30800],
          ['Week7',60000, 40800]
        ],
        options: {'title': '','height':400},
      };

    }
    else if (this.selectedCity == "Albama") {
      this.barChartAlbama = {
        chartType: 'ColumnChart',
        height:350,
        dataTable: [
          ['Week1', 'Positive Cases','Deaths'],
          ['Week1', 21000, 5000],
          ['Week2', 22000, 11000],
          ['Week3', 23022, 5000],
          ['Week4',  24000, 12034],
          ['Week5', 25000, 1234],
          ['Week6',21000, 12034],
          ['Week7',22000, 1234]
          
        ],
        options: {'title': '','height':400}
      };
    }
    else if (this.selectedCity == "Newyork") {
      this.barChartNewyork = {
        height:350,
        chartType: 'ColumnChart',
        dataTable: [
          ['Week1', 'Positive Cases','Deaths'],
          ['Week1', 27000, 5000],
          ['Week2', 28000, 10000],
          ['Week3', 19022, 5000],
          ['Week4',  30000, 12034],
          ['Week5', 31000, 1234],
          ['Week6',27000, 12034],
          ['Week7',28000, 1234]
        ],
        options: {'title': '','height':400}
      };
    }
  }


  onChangeEvent(city) {
    this.bindChartData();
    this.getRegionofIncidentList();
  }
  getRegionofIncidentList() {
    debugger;
    if(this.selectedCity == "New Jersey"){
      this.regionList = [
        {
          week: 'Week1 ',
          city: 'New Jersey',
          totalTest: '25000',
          positiveCases: '25000',
          deaths: '12000'
        },
        {
          week: 'Week2 ',
          city: 'New Jersey',
          totalTest: '2020120',
          positiveCases: '20000',
          deaths: '10000'
        },
        {
          week: 'Week3',
          city: 'New Jersey',
          totalTest: '2020120',
          positiveCases: '12022',
          deaths: '5000'
        },
        {
          week: 'Week4 ',
          city: 'New Jersey',
          totalTest: '2020120',
          positiveCases: '30000',
          deaths: '12034'
        },
        {
          week: 'Week5 ',
          city: 'New Jersey',
          totalTest: '2020120',
          positiveCases: '50000',
          deaths: '1234'
        },
         {
          week: 'Week6 ',
           city: 'Alaska',
           totalTest: '2020120',
          positiveCases: '40000',
           deaths: '30800'
        },
        {
          week: 'Week7 ',
           city: 'Alaska',
           totalTest: '2020120',
          positiveCases: '60000',
           deaths: '40800'
        },


      ];
    }
    else if(this.selectedCity == "Albama"){
      this.regionList = [
        {
          week: 'Week1 ',
          city: 'Albama',
          totalTest: '2000',
          positiveCases: '12000',
          deaths: '12000'
        },
        {
          week: 'Week2 ',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '21000',
          deaths: '5000'
        },
        {
          week: 'Week3',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '23022',
          deaths: '5000'
        },
        {
          week: 'Week4 ',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '24000',
          deaths: '12034'
        },
        {
          week: 'Week5 ',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '25000',
          deaths: '1234'
        },
        {
          week: 'Week6 ',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '21000',
          deaths: '12034'
        },
        {
          week: 'Week7 ',
          city: 'Albama',
          totalTest: '2020120',
          positiveCases: '22000',
          deaths: '1234'
        },
       

      ];
    }
    else if(this.selectedCity == "Newyork"){
      this.regionList = [
        {
          week: 'Week1 ',
          city: 'New York',
          totalTest: '2000',
          positiveCases: '27000',
          deaths: '5000'
        },
        {
          week: 'Week2 ',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '28000',
          deaths: '10000'
        },
        {
          week: 'Week3',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '19022',
          deaths: '5000'
        },
        {
          week: 'Week4 ',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '30000',
          deaths: '12034'
        },
        {
          week: 'Week5 ',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '31000',
          deaths: '1234'
        },
        {
          week: 'Week6 ',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '27000',
          deaths: '12034'
        },
        {
          week: 'Week7 ',
          city: 'New York',
          totalTest: '2020120',
          positiveCases: '28000',
          deaths: '1234'
        },
      

      ];
    }
  }
}



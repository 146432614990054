import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-resourcesofallocation',
  templateUrl: './resourcesofallocation.component.html',
  styleUrls: ['./resourcesofallocation.component.scss']
})
export class ResourcesofallocationComponent implements OnInit {
  selectedCity = 'New Jersey';
 
  barChart: any;
  barChartAlbama:any;
  barChartNewyork:any;
  constructor() { }

  ngOnInit() {
    this.bindChartData();
  }
  bindChartData() {
    this.barChart =null;
    if (this.selectedCity == "New Jersey") {
      this.barChart = {
        chartType: 'ColumnChart',
        dataTable: [
          ['State', 'Hospitals','CovidCenters', 'Doctors', 'Nurses', 'PPE', 'Ventilators'],
          ['Resources',  50,40,500,700,800,300]
          // ['Doctors',  0,      200,        0,            0,          0],
          // ['Nurses',  0,      0,        300,             0,           0],
          // ['PPE',  0,      0,        0,             400,           0],
          // ['Ventilators',  0,      0,         0,             0,          500]
    ],
        options: {'title': '','height':500,
        seriesType: 'bars',
      },
      };

    }
    else if (this.selectedCity == "Albama") {
      this.barChartAlbama = {
        chartType: 'ColumnChart',
        
        dataTable: [
          ['State', 'Hospitals','CovidCenters', 'Doctors', 'Nurses', 'PPE', 'Ventilators'],
          ['Resources',  60,50,500,600,800,300]
        ],
        options: {'title': '','height':500,
        
        seriesType: 'bars',
      }
      };
    }
    else if (this.selectedCity == "Newyork") {
      this.barChartNewyork = {
        
        chartType: 'ColumnChart',
        dataTable: [
          ['State', 'Hospitals','CovidCenters', 'Doctors', 'Nurses', 'PPE', 'Ventilators'],
          ['Resources',  40,200,500,600,200,300]
        ],
        options: {'title': '','height':500,
        seriesType: 'bars',
      }
      };
    }
  }
  onChangeEvent(city) {
    this.bindChartData();
    
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-worldcovidstatus',
  templateUrl: './worldcovidstatus.component.html',
  styleUrls: ['./worldcovidstatus.component.scss']
})
export class WorldcovidstatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

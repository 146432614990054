// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
   
  covid19URL: 'https://api.covid19india.org/v2/state_district_wise.json',
  covidWorldStatusURL: 'https://coronavirus-19-api.herokuapp.com/countries',
  travelhistory: 'https://api.covid19india.org/travel_history.json',  
    //apiURL: 'http://localhost:5000/api'
   
apiURL:'https://plumvtracker.ukitss.com/api'
  //apiURL:'https://plumvtracker.com/api'
  //https://plumvtracker.com/
  //apiURL: 'http://10.0.1.240:8057/api'
};

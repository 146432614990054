import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { SymptomchekerService } from 'app/services/SymptomchekerService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButton, MatProgressBar } from '@angular/material';
import { AuthService } from 'app/services/AuthService';
import { MatDialog } from '@angular/material';
import { CovidResultsComponent } from '../covid-results/covid-results.component';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { DialogType } from 'app/helpers/app-enums';
@Component({
  selector: 'app-addsymptomchecker',
  templateUrl: './addsymptomchecker.component.html'

})
export class AddsymptomcheckerComponent implements OnInit {
  AddSymptomCheckerForm: FormGroup
  error: string;
  patientId: any;
  patientName: any;
  currentUserId: any;
  callFrom = 0;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  constructor(private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService,
    private symptomchekerService: SymptomchekerService,
    public dialog: MatDialog,
    private authService: AuthService) { }

  ngOnInit() {
    this.patientId = this.route.snapshot.paramMap.get('id');
    this.patientName = this.route.snapshot.paramMap.get('name');
    this.callFrom = parseInt(this.route.snapshot.paramMap.get('callFrom'));
    this.currentUserId = this.authService.currentUserToken.id
    this.initForm();
  }

  initForm() {
    this.AddSymptomCheckerForm = new FormGroup({
      UserId: new FormControl(this.patientId),
      Fever: new FormControl(0),
      Cough: new FormControl(0),
      RunnyNose: new FormControl(0),
      BodyAche: new FormControl(0),
      Chill: new FormControl(0),
      HeadAche: new FormControl(0),
      Diarrhea: new FormControl(0),
      Nausea: new FormControl(0),
      ShortBreath: new FormControl(0),
      CovidStatus: new FormControl(),
      CovidStatusStr: new FormControl(),
      MusclePain: new FormControl(0),
      LosOfApetie: new FormControl(0),
      LoseOfTaste: new FormControl(0),
      Days: new FormControl('0'),
      IsTravelHistory: new FormControl(true),
      Score: new FormControl(),
      ChestPain: new FormControl(0),
      AbdominalPain: new FormControl(0),
      Fatigue: new FormControl(0),
      CovidStatusId: new FormControl(),
      SoreThroat: new FormControl(0),
      CreatedById: new FormControl(this.currentUserId),
      UpdatedById: new FormControl(this.currentUserId),
      IsActive: new FormControl(true),
    })
  }

  saveData() {

    let data = this.AddSymptomCheckerForm.value;
    data.CovidStatusId = 1;
    data.UserId = this.patientId;
    data.Score = parseInt(data.Fever) + parseInt(data.Cough) + parseInt(data.BodyAche) + parseInt(data.RunnyNose)
      + parseInt(data.ShortBreath) + parseInt(data.Chill) + parseInt(data.HeadAche) + parseInt(data.Diarrhea)
      + parseInt(data.Nausea) + parseInt(data.MusclePain) + parseInt(data.LosOfApetie) + parseInt(data.LoseOfTaste)
      + parseInt(data.ChestPain) + parseInt(data.AbdominalPain) + parseInt(data.Fatigue) + parseInt(data.SoreThroat);
    if (data.Score <= 60) {
      data.CovidStatus = 0;
      data.CovidStatusStr = "None";
    }
    else if (data.Score >= 61 && data.Score <= 120) {
      data.CovidStatus = 1;
      data.CovidStatusStr = "Low";
    }
    else if (data.Score >= 121 && data.Score <= 180) {
      data.CovidStatus = 2;
      data.CovidStatusStr = "Medium";
    }
    else if (data.Score >= 181) {
      data.CovidStatus = 3;
      data.CovidStatusStr = "High";
    }
    this.symptomchekerService.saveSymptomCheckerData(data)
      .subscribe(
        data => {
          let newdata = this.AddSymptomCheckerForm.value;

          //        this.toastr.info(data.result);
          // this.toastr.info(data.result +" And Your Score is "+newdata.Score+" And Your covid status is "+newdata.CovidStatusStr);
          //let msg = data.result + " And Your Score is " + newdata.Score + " And Your covid status is " + newdata.CovidStatusStr;
          let msg = `Your corona Status is <strong>${newdata.CovidStatusStr}</strong>`;
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              DialogType: DialogType.Alert,
              Title: "Message",
              Message: msg
            }
          });


          if (this.callFrom == 0) {
            this.router.navigate(["/User"]);
          }
          else if (this.callFrom == 1) {
            this.router.navigate(["/symtoms_score"]);
          }
          else if (this.callFrom == 2) {
            this.router.navigate(["/managepatient-list"]);
          }
          else {
            this.router.navigate(["/symtoms_score"]);
          }
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }
}

import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { SigninComponent } from './common/signin/signin.component';
import { SignupComponent } from './common/signup/signup.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { ErrorComponent } from './common/error/error.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { ProfileComponent } from './common/profile/profile.component';
import { EditProfileComponent } from './common/profile/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './common/profile/change-password/change-password.component';


import { AppChatsComponent } from './pages/messaging/app-chats.component';
import { DoctorListComponent } from './pages/users/doctor-list/doctor-list.component';
import { MedicalOfficerListComponent } from './pages/users/medical-officer-list/medical-officer-list.omponent';
import { UserAddEditComponent } from './pages/users/user-add-edit/user-add-edit.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { CoronaIndiaStatComponent } from './pages/corona-stat/corona-india-stat/corona-india-stat.component';
import { CoronaStateStatComponent } from './pages/corona-stat/corona-state-stat/corona-state-stat.component';

import { InteractionListComponent } from './pages/interaction-list/interaction-list.component';
import { SymptomschekerComponent } from './pages/symptomscheker/symptomscheker.component';
import { PatienttravellhistoryComponent } from './pages/patienttravellhistory/patienttravellhistory.component';
import { SymptomchekerdetailsComponent } from './pages/symptomchekerdetails/symptomchekerdetails.component';
import { WorldStatComponent } from './pages/coronaworld-stat/world-stat.component';
import { UserComponent } from './pages/user/user.component';
import { OuterLayoutComponent } from './shared/components/layouts/outer-layout/outer-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyAppComponent } from './common/privacy-policy-app/privacy-policy-app.component';
import { MalawicoronastatusComponent } from './pages/malawicoronastatus/malawicoronastatus.component';
import { TrackingHistoryComponent } from './pages/tracking-history/tracking-history.component';
import { TrackingUserHistoryComponent } from './pages/tracking-user-history/tracking-user-history.component';
import { SymptomChekerDetailsByUserIdComponent } from './pages/symptom-cheker-details-by-user-id/symptom-cheker-details-by-user-id.component';
import { GooglemapComponent } from './pages/googlemap/googlemap.component';
import { ManagepatientlistComponent } from './pages/managepatientlist/managepatientlist.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NewsComponent } from './pages/news/news.component';
import { VaccinesComponent } from './pages/vaccines/vaccines.component';
import { UpdatecovidnewsComponent } from './pages/updatecovidnews/updatecovidnews.component';
import { CovidlongtermeffectsComponent } from './pages/covidlongtermeffects/covidlongtermeffects.component';
import { WorldcovidstatusComponent } from './pages/worldcovidstatus/worldcovidstatus.component';
import { CovidvaccinedetailComponent } from './pages/covidvaccinedetail/covidvaccinedetail.component';
import { CovidapprovedvaccinesComponent } from './pages/covidapprovedvaccines/covidapprovedvaccines.component';
import { CovidvaccinedevelopmentComponent } from './pages/covidvaccinedevelopment/covidvaccinedevelopment.component';
import { AddsymptomcheckerComponent } from './pages/addsymptomchecker/addsymptomchecker.component';
import { NewtrackinguserhistoryComponent } from './pages/newtrackinguserhistory/newtrackinguserhistory.component';
import { SymptomcheckerscoreComponent } from './pages/symptomcheckerscore/symptomcheckerscore.component';
import { ImportPatientComponent } from './pages/import-patient/import-patient.component';
import { CovidResultsComponent } from './pages/covid-results/covid-results.component';
import { PatientregistrationComponent } from './pages/patientregistration/patientregistration.component';
import { DoctorregistrationComponent } from './pages/doctorregistration/doctorregistration.component';
import { DigitalofficerregistrationComponent } from './pages/digitalofficerregistration/digitalofficerregistration.component';
import { DoctorSignupComponent } from './pages/doctor-signup/doctor-signup.component';
import { DigitalofficerSignupComponent } from './pages/digitalofficer-signup/digitalofficer-signup.component';
import { CoronaAffectedareaComponent } from './pages/reports/corona-affectedarea/corona-affectedarea.component';
import { WhocbcComponent } from './pages/whocbc/whocbc.component';
import { VacinprogressChartComponent } from './pages/reports/vacinprogress-chart/vacinprogress-chart.component';
import { RegionofIncidentchartComponent } from './pages/reports/regionof-incidentchart/regionof-incidentchart.component';
import { VaccineProgressComponent } from './pages/reports/vaccine-progress/vaccine-progress.component';
import { HotSpotAreaComponent } from './pages/reports/hot-spot-area/hot-spot-area.component';
import { RegionofIncidentComponent } from './pages/reports/regionof-incident/regionof-incident.component';
import { LockdownMovementComponent } from './pages/reports/lockdown-movement/lockdown-movement.component';
import { ResourcesofallocationComponent } from './pages/reports/resourcesofallocation/resourcesofallocation.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PostivityRateComponent } from './reports/postivity-rate/postivity-rate.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '', component: OuterLayoutComponent, children: [
      { path: '', component: HomeComponent, data: { title: 'Home' } },
      { path: 'home', component: HomeComponent, data: { title: 'Home' } },
      { path: 'privacy-policy-app', component: PrivacyPolicyAppComponent, data: { title: 'Privacy & Pilicy' } },
      // { path: 'header', component: HeaderComponent, data: { title: 'Header' } },
      // { path: 'footer', component: FooterComponent, data: { title: 'Footer' } },
      { path: 'news', component: NewsComponent, data: { title: 'News' } },
      { path: 'vaccines', component: VaccinesComponent, data: { title: 'Vaccines' } },
      { path: 'updatecovidnews', component: UpdatecovidnewsComponent, data: { title: 'Update Covid News' } },
      { path: 'covidlongtermeffects', component: CovidlongtermeffectsComponent, data: { title: 'Covid Long Term Effects' } },
      { path: 'worldcovidstatus', component: WorldcovidstatusComponent, data: { title: 'World Covid Status' } },
      { path: 'covidvaccinedetail', component: CovidvaccinedetailComponent, data: { title: 'Covid Vaccine Detail' } },
      { path: 'covidapprovedvaccines', component: CovidapprovedvaccinesComponent, data: { title: 'Covid Approved Vaccines' } },
      { path: 'covidvaccinedevelopment', component: CovidvaccinedevelopmentComponent, data: { title: 'Covid Vaccine Development' } },
      { path: 'patientregistration', component: PatientregistrationComponent, data: { title: 'Patient Registration' } },
      { path: 'doctorregistration', component: DoctorregistrationComponent, data: { title: 'Doctor Registration' } },
      { path: 'signupdoctor', component: DoctorSignupComponent, data: { title: 'Doctor Registration' } },
      { path: 'signupdigitalofficer', component: DigitalofficerSignupComponent, data: { title: 'Doctor Registration' } },
      { path: 'signin', component: SigninComponent, data: { title: 'SignIn' } },
      { path: 'signup', component: SignupComponent, data: { title: 'SignUp' } },
      //{ path: 'coronaafectedarea', component: CoronaAffectedareaComponent, data: { title: 'HotSpotArea' } },
      { path: 'digitalofficerregistration', component: DigitalofficerregistrationComponent, data: { title: 'Digital Officer Registration' } },
      { path: 'whocbc', component: WhocbcComponent, data: { title: 'WHO CBC' } },
      { path: 'aboutus', component: AboutusComponent, data: { title: 'About Us' } }
    ]
  },
  {
    path: '', component: AdminLayoutComponent, children: [
      { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard', breadcrumb: 'Dashboard' }, canActivate: [AuthGuard] },
      {
        path: '', component: ProfileComponent, children: [
          // { path: 'my-profile', component: EditProfileComponent, data: { title: 'My Profile', breadcrumb: 'My Profile' }, canActivate: [AuthGuard] },
          // { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change Password' }, canActivate: [AuthGuard] }
        ]
      },
      //{ path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard', breadcrumb: 'Dashboard' }, canActivate: [AuthGuard] },
      { path: 'tracking-history', component: TrackingHistoryComponent, data: { title: 'Tracking  History' }, canActivate: [AuthGuard] },
      { path: 'tracking-history/:userId', component: TrackingHistoryComponent, data: { title: 'Tracking  History' }, canActivate: [AuthGuard] },
      { path: 'SymptomListByUserId/:userId', component: SymptomChekerDetailsByUserIdComponent, data: { title: 'Tracking  History' }, canActivate: [AuthGuard] },
      { path: 'tracking-userhistory', component: TrackingUserHistoryComponent, data: { title: 'User Tracking History' }, canActivate: [AuthGuard] },
      { path: 'my-profile', component: EditProfileComponent, data: { title: 'My Profile', breadcrumb: 'My Profile' }, canActivate: [AuthGuard] },
      { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change Password' }, canActivate: [AuthGuard] },
      { path: 'doctors', component: DoctorListComponent, data: { title: 'Doctors' }, canActivate: [AuthGuard] },
      { path: 'doctors/add', component: UserAddEditComponent, data: { title: 'Doctor Add' }, canActivate: [AuthGuard] },
      { path: 'doctors/edit/:id', component: UserAddEditComponent, data: { title: 'Doctor Edit' }, canActivate: [AuthGuard] },
      { path: 'doctors/detail/:id', component: UserDetailComponent, data: { title: 'Doctor Detail' }, canActivate: [AuthGuard] },
      { path: 'medical-officers', component: MedicalOfficerListComponent, data: { title: 'Medical Officers' }, canActivate: [AuthGuard] },
      { path: 'medical-officers/add', component: UserAddEditComponent, data: { title: 'Medical Officer Add' }, canActivate: [AuthGuard] },
      { path: 'medical-officers/edit/:id', component: UserAddEditComponent, data: { title: 'Medical Officer Edit' }, canActivate: [AuthGuard] },
      { path: 'medical-officers/detail/:id', component: UserDetailComponent, data: { title: 'Medical Officer Detail' }, canActivate: [AuthGuard] },
      { path: 'corona-stat/india', component: CoronaIndiaStatComponent, data: { title: 'India status' }, canActivate: [AuthGuard] },
      { path: 'malawicoronstatus', component: MalawicoronastatusComponent, data: { title: 'Malawi status' }, canActivate: [AuthGuard] },
      { path: 'corona-stat/india/:id', component: CoronaStateStatComponent, data: { title: 'India status' }, canActivate: [AuthGuard] },
      { path: 'message', component: AppChatsComponent, data: { title: 'Messaging' }, canActivate: [AuthGuard] },
      { path: 'symtoms_score', component: SymptomschekerComponent, data: { title: 'Sypmtom score' }, canActivate: [AuthGuard] },
      { path: 'symtoms_score/:userId', component: SymptomschekerComponent, data: { title: 'Sypmtom score' }, canActivate: [AuthGuard] },
      { path: 'interaction_list', component: InteractionListComponent, data: { title: 'Interaction List' }, canActivate: [AuthGuard] },
      { path: 'symtoms_score/details/:id', component: SymptomchekerdetailsComponent, data: { title: 'patient Symptom-Details' }, canActivate: [AuthGuard] },
      { path: 'travel_history/:userId', component: PatienttravellhistoryComponent, data: { title: 'Interaction List' }, canActivate: [AuthGuard] },
      { path: 'world-stat', component: WorldStatComponent, data: { title: 'World Status' }, canActivate: [AuthGuard] },
      { path: 'User', component: UserComponent, data: { title: 'User' }, canActivate: [AuthGuard] },
      { path: 'GoogleMap', component: GooglemapComponent, data: { title: 'GoogleMap' }, canActivate: [AuthGuard] },
      { path: 'managepatient-list', component: ManagepatientlistComponent, data: { title: 'PatientList' }, canActivate: [AuthGuard] },
      { path: 'symptomchecker/:id/:name/:callFrom', component: AddsymptomcheckerComponent, data: { title: 'Symptom Checker' }, canActivate: [AuthGuard] },
      { path: 'newpatienttracking-list', component: NewtrackinguserhistoryComponent, data: { title: 'PatientList' }, canActivate: [AuthGuard] },
      { path: 'symptomcheckerscore', component: SymptomcheckerscoreComponent, data: { title: 'Symptom checker score' }, canActivate: [AuthGuard] },
      { path: 'symptomchecker/:id', component: AddsymptomcheckerComponent, data: { title: 'Symptom Checker' }, canActivate: [AuthGuard] },
      { path: 'importpatient', component: ImportPatientComponent, data: { title: 'Import Patient' }, canActivate: [AuthGuard] },
      { path: 'covidResult', component: CovidResultsComponent, data: { title: 'Covid Result' }, canActivate: [AuthGuard] },
      { path: 'regionofincident', component: RegionofIncidentComponent, data: { title: 'Region of Incident' } },
      { path: 'hotspotarea', component: HotSpotAreaComponent, data: { title: 'Hot Spot Area' } },
      { path: 'coronaaffectedarea', component: CoronaAffectedareaComponent, data: { title: 'Hot Spot Area' },canActivate: [AuthGuard] },
      { path: 'vacine-progress-chart', component: VacinprogressChartComponent, data: { title: 'Vacine Chart' },canActivate: [AuthGuard] },
      { path: 'region-Incident-chart', component: RegionofIncidentchartComponent, data: { title: 'incident Chart' },canActivate: [AuthGuard] },
      { path: 'positivity-Report', component: PostivityRateComponent, data: { title: 'Positivity Report Chart' },canActivate: [AuthGuard] },
      { path: 'lockdown-movement-report', component: LockdownMovementComponent, data: { title: 'Lock Down Movement' },canActivate: [AuthGuard] },
      { path: 'resourcesofallocation', component: ResourcesofallocationComponent, data: { title: 'incident Chart' },canActivate: [AuthGuard] },
      { path: 'vaccineprogress', component: VaccineProgressComponent, data: { title: 'Vaccine Progress' } },
    ]
  },
  {
    path: '', component: AuthLayoutComponent, children: [
     
     
      { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
      { path: 'reset-password/:userEmail', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
    ]
  },

  {
    path: "404",
    component: NotFoundComponent,
    data: { title: "Not Found" }
  },
  {
    path: "error",
    component: ErrorComponent,
    data: { title: "Error" }
  },
  {
    path: '**',
    redirectTo: '404'
  }
];


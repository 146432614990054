import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { AccountService } from 'app/services/AccountService';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { ProfileService } from 'app/services/ProfileService';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit {
  //@ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  signupForm: FormGroup
  error: string;
  specialities = [];

  constructor(private router: Router, private toastr: ToastrService, private accountService: AccountService, private profileService: ProfileService) { }
  ngOnInit() {
    this.initForm();
    this.manageValidationsOnRoleChange();
    this.getDoctorSpecialities();
  }
  initForm() {
    const password = new FormControl('', Validators.required);
    const confirmPassword = new FormControl('', CustomValidators.equalTo(password));
    this.signupForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      mobileNumber: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      role: new FormControl(''),
      licenceNumber: new FormControl(),
      department: new FormControl(),
      speciality: new FormControl(),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: password,
      confirmPassword: confirmPassword,

    })
  }
  manageValidationsOnRoleChange() {
  //   this.signupForm.get('role').valueChanges
  //     .subscribe(value => {
  //       if (value === 'provider') {
  //         this.signupForm.get('licenceNumber').setValidators([Validators.required])
  //         this.signupForm.get('speciality').setValidators([Validators.required])
  //       } else {
  //         this.signupForm.get('department').clearValidators();

  //       }
  //       if (value === 'DigitalOfficer') {
  //         this.signupForm.get('department').setValidators([Validators.required])
  //       } else {
  //         this.signupForm.get('licenceNumber').clearValidators();
  //         this.signupForm.get('speciality').clearValidators();
  //       }
  //     });
   }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.signupForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getDoctorSpecialities() {
    this.profileService.getDoctorSpeciality()
      .subscribe(
        data => {
          debugger;
          this.specialities = data.result;
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }


  signup() {
    debugger;
    const signupData = this.signupForm.value;
    console.log(signupData);
    var data = this.findInvalidControls();

    this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    this.accountService.registerUser(signupData.firstName, signupData.lastName, signupData.email, signupData.password,
      signupData.mobileNumber, signupData.gender, signupData.address, signupData.role, signupData.licenceNumber, signupData.department, signupData.speciality)

      .subscribe(
        data => {
          debugger;
          this.toastr.info(data.result);
          this.router.navigate(["/signin"]);
        },
        error => {
          debugger;
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          //this.progressBar.mode = 'determinate';
        });
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from 'app/services/UserService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent implements OnInit {

  id: any;
  role: string;
  user: any;
  loading: boolean;
  error: any;

  constructor(private route: ActivatedRoute, private location: Location, private cdr: ChangeDetectorRef, private userService: UserService, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.route.queryParams.subscribe(params => {
      this.role = params["role"];
    });
    this.getItems();
  }

  getItems() {
    this.loading = true;
    this.userService.getById(this.id)
      .subscribe(data => {        
          this.user = data.result;          
          this.loading = false;
          this.cdr.markForCheck();
        },
        error=> {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }
}

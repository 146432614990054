import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covidvaccinedevelopment',
  templateUrl: './covidvaccinedevelopment.component.html',
  styleUrls: ['./covidvaccinedevelopment.component.scss']
})
export class CovidvaccinedevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { CoronaStatService } from 'app/services/CoronaStatService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'corona-state-stat',
  templateUrl: './corona-state-stat.component.html',
  animations: egretAnimations
})
export class CoronaStateStatComponent implements OnInit {
  id: string;
  districtData: any[];
  stateConfirmed:any;
  temp: any;
  constructor(private route: ActivatedRoute, private location: Location, private cdr: ChangeDetectorRef, private coronaStatService: CoronaStatService, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.getItems();
  }

  getItems() {
    this.coronaStatService.getCoronaStats()
      .subscribe(data => {
        let state = data.filter((state) => {
          return state.state === this.id;
        });
        this.districtData = state[0].districtData;   
        this.stateConfirmed = this.districtData.reduce((prev, cur) => prev + cur.confirmed, 0);     
        this.cdr.markForCheck()
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }
}
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { first } from 'rxjs/operators';


import { ToastrService } from 'ngx-toastr';
import {WorldwisepatientService  } from 'app/services/worldwisepatientService';
@Component({
  selector: 'app-world-stat',
  templateUrl: './world-stat.component.html',
  styleUrls: ['./world-stat.component.scss'],
  animations: egretAnimations
})
export class WorldStatComponent implements OnInit {
  result: any[];
  temp: any;
  items: any[];
  constructor(private cdr: ChangeDetectorRef, private worldwisepatientService: WorldwisepatientService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getItems();
    window.scroll(0,0)
  }
  getItems() {

    this.worldwisepatientService.getWorldCovidStatus()
    
      .subscribe(data => {             
        this.items = this.temp = data;        
         console.log(this.result);
        this.cdr.markForCheck();
        },            
       
    
      error => {
        this.toastr.error(error.error.responseException.exceptionMessage);
      });
  }
  filterItems(event) {
    const val = event.target.value.toLowerCase();

    this.items = this.temp.filter(singleItem =>
      singleItem['country'].toString().toLowerCase().includes(val)
      //  singleItem['lastName'].toString().toLowerCase().includes(val) ||
      //  singleItem['middleName'].toString().tosLowerCase().includes(val)
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-regionof-incident',
  templateUrl: './regionof-incident.component.html',
  styleUrls: ['./regionof-incident.component.scss']
})
export class RegionofIncidentComponent implements OnInit {
  regionList: any;
  selectedCity = 'New Jersey';
  data: [];
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
  };
  regionChart: any;
  regionChartAlbama:any;
  regionChartNewyork:any;
  constructor() {
   
   
   }

  ngOnInit() {
    this.getRegionofIncidentList();
    this.bindChartData();
     this.data = this.regionList;
    console.log("data", this.regionList)
  }
  bindChartData() {
    debugger;
    this.regionChart =null;
    if (this.selectedCity == "New Jersey") {
      this.regionChart = {
        chartType: 'ColumnChart',
        height:350,
        dataTable: [
          ['Region Of Incident', 'No Of Test','No Of Positive'],
          ['Week1', 25000, 12000],
          ['Week2', 20000, 10000],
          ['Week3', 12022, 5000],
          ['Week4',  30000, 12034],
          ['Week5', 50000, 1234],
          ['Week6',30000, 18000],
          ['Week7',80000, 40800]
        ],
        options: {'title': '','height':400},
      };

    }
    else if (this.selectedCity == "Albama") {
      this.regionChartAlbama = {
        chartType: 'ColumnChart',
        height:350,
        dataTable: [
          ['Region Of Incident', 'No Of Test','No Of Positive'],
          ['Week1', 30000, 15000],
          ['Week2', 21000, 11000],
          ['Week3', 13022, 5300],
          ['Week4',  34000, 12034],
          ['Week5', 50000, 1234],
          ['Week6',30000, 20000],
          ['Week7',85000, 40800]
        ],
        options: {'title': '','height':400}
      };
    }
    else if (this.selectedCity == "Newyork") {
      this.regionChartNewyork = {
        height:350,
        chartType: 'ColumnChart',
       
        dataTable: [
          ['Region Of Incident', 'No Of Test','No Of Positive'],
          ['Week1', 250000, 120000],
          ['Week2', 200000, 100000],
          ['Week3', 120220, 50000],
          ['Week4',  300000, 120340],
          ['Week5', 500000, 12340],
          ['Week6',850000, 40800],
          ['Week7',90000, 40800]
        ],
        options: {'title': '','height':400}
      };
    }
  }
  onChangeEvent(city) {
    debugger;
    this.bindChartData();
    this.getRegionofIncidentList();
  }
  getRegionofIncidentList() {
    if(this.selectedCity == "New Jersey"){
    this.regionList = [
      {   
        week: 'Week1 ',     
        date: '02-02-2021 ',
        city : 'New Jersey',
        totalTest : '25000',
        positiveCases : '12000'
      },
      {   week: 'Week2 ', 
        date: '02-02-2021 ',
        city: 'New Jersey',
        totalTest: '20000',
        positiveCases: '10000'
      },
      {
        week: 'Week3 ',
        date: '02-02-2021 ',
        city: 'New Jersey',
        totalTest: '12022',
        positiveCases: '5000'
      },
      {
        week: 'Week4 ',
        date: '02-02-2021 ',
        city: 'New Jersey',
        totalTest: '30000',
        positiveCases: '12034'
      },
      { week: 'Week5 ',
        date: '02-02-2021 ',
        city: 'New Jersey',
        totalTest: '50000',
        positiveCases: '1234'
      },
      { week: 'Week6 ',
      date: '02-02-2021 ',
      city: 'New Jersey',
      totalTest: '30000',
      positiveCases: '18000'
    },
    { week: 'Week7 ',
    date: '02-02-2021 ',
    city: 'New Jersey',
    totalTest: '80000',
    positiveCases: '40800'
  },
     
    ];
  } 
  else if(this.selectedCity == "Albama"){
    this.regionList = [
      {  week: 'Week1 ',       
        date: '02-02-2021 ',
        city : 'Albama',
        totalTest : '30000',
        positiveCases : '15000'
      },
      {    week: 'Week2 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '21000',
        positiveCases: '11000'
      },
      {
        week: 'Week3 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '13022',
        positiveCases: '5300'
      },
      {
        week: 'Week4 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '34000',
        positiveCases: '12034'
      },
      {
        week: 'Week5 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '50000',
        positiveCases: '1234'
      },
      {
        week: 'Week6 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '30000',
        positiveCases: '20000'
      },
      {
        week: 'Week7 ',
        date: '02-02-2021 ',
        city: 'Albama',
        totalTest: '85000',
        positiveCases: '40800'
      },
     
    ];
  }
  else if(this.selectedCity == "Newyork"){
    this.regionList = [
      { week: 'Week1 ',    
        date: '02-02-2021 ',
        city : 'New York',
        totalTest : '250000',
        positiveCases : '120000'
      },
      {    week: 'Week2 ',
        date: '02-02-2021 ',
        city: 'New York',
        totalTest: '200000',
        positiveCases: '100000'
      },
      {
        week: 'Week3 ',
        date: '02-02-2021 ',
        city: 'New york',
        totalTest: '120220',
        positiveCases: '50000'
      },
      {
        week: 'Week4 ',
        date: '02-02-2021 ',
        city: 'New York',
        totalTest: '300000',
        positiveCases: '120340'
      },
      {
        week: 'Week5 ',
        date: '02-02-2021 ',
        city: 'New York',
        totalTest: '500000',
        positiveCases: '12340'
      },
      {
        week: 'Week6 ',
        date: '02-02-2021 ',
        city: 'New York',
        totalTest: '850000',
        positiveCases: '40800'
      },
      {
        week: 'Week7 ',
        date: '02-02-2021 ',
        city: 'New York',
        totalTest: '90000',
        positiveCases: '40800'
      },
     
     
    ];
  }     
  }
}


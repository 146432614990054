import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    constructor(private http: HttpClient) { }
       
    getProfile() {        
        return this.http.get<any>(`${environment.apiURL}/profile/getprofile`);
    }
    getDoctorSpeciality() {        
        return this.http.get<any>(`${environment.apiURL}/Users/SpecialityList`);
    }

    //  updateProfile(id:number, firstName:string, lastName:string,title:string, phone:string, dob:Date, age:number,address:string, city:number,state:number,zipcode:number){        
    //      return this.http.put<any>(`${environment.apiURL}/Profile/UpdateProfile`, {id, firstName, lastName, title, phone, dob, address, city,state,zipcode});
    //  }
    updateProfile(id:number, firstName:string, lastName:string,email:string,address:string,licenceNumber:string,department:string,speciality:string){ 
               
              return this.http.post<any>(`${environment.apiURL}/profile/updateprofile`, {id, firstName, lastName,cityId:0,email,address,licenceNumber,department,speciality});
        }
    // updateProfile(UserProfile: any) {
    //     return this.http.post<any>(`${environment.apiURL}/profile/UpdateProfile`, UserProfile);
    //   }
    // http://localhost:5000/api/Profile/UpdateProfile

    changePassword(id:number, oldPassword:string, newPassword:string){
        return this.http.post<any>(`${environment.apiURL}/profile/changepassword`, { id, oldPassword, newPassword});
    }
}
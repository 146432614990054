import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'new-pateint-chart',
  templateUrl: './new-pateint-chart.component.html'
})
export class NewPatientChartComponent implements OnInit { 
  chartType: any;
  chartOptions: any;
  chartColors: any;
  labels: any;
  data: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.initilizeChart();
  }

  initilizeChart() {
    this.chartType = 'line';

    this.chartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      legend: {
        display: true,
        labels: {
          fontColor: 'rgb(255, 255, 255)'
        }
      },
      scales: {
        xAxes: [{
          ticks: { fontColor: 'white' },
          gridLines: { zeroLineColor: 'rgba(255,255,255,0.2)', color: 'rgba(255,255,255,0.1)' }
        }],
        yAxes: [{
          ticks: { fontColor: 'white' },
          gridLines: { zeroLineColor: 'rgba(255,255,255,0.2)', color: 'rgba(255,255,255,0.1)' }
        }]
      }
    };

    this.chartColors = [
      {
        backgroundColor: '#ffa500',
        borderColor: '#ffa500',
        fontColor: '#fff'
      },
      {
        backgroundColor: '#27a9d8',
        borderColor: '#27a9d8',
        fontColor: '#fff'
      }
    ];
    this.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    this.data = [
      {data: [0, 10, 20, 30, 37, 52, 65], label: 'Doctor', fill:false},
      {data: [0, 15, 26, 37, 40, 55, 75], label: 'Location',fill:false},
    ];
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';


import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/services/userService';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { DialogButtonType, DialogResult } from 'app/helpers/app-enums';
import { ImportPatientComponent } from '../import-patient/import-patient.component';
import { AuthService } from 'app/services/AuthService';

@Component({
  selector: 'app-managepatientlist',
  templateUrl: './managepatientlist.component.html',
  styleUrls: ['./managepatientlist.component.scss']
})
export class ManagepatientlistComponent implements OnInit {

  userNameChanged: Subject<string> = new Subject<string>();
  result: any[];
  userId: any;
  userName = '';
  mobileNumber = '';
  patientType=0;

  role = '';
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
    orderBy: 'FirstName',
    orderDir: 'asc'
  };

  constructor(private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private toastr: ToastrService,
    private authService: AuthService,
    public dialog: MatDialog) {

  }



  ngOnInit() {
    if (this.route.snapshot.paramMap.get('userId')) {
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    this.getItems();

  }
  clear() {
    this.userName = '';
    this.mobileNumber = '';
    this.patientType=0;
    this.role = '';
    this.page = {
      pageNumber: 0,
      itemCount: 0,
      pageSize: 10,
      orderBy: '',

      orderDir: ''
    };

    this.getItems();
  }

  onPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getItems();
  }
  onSort(sortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getItems();
  }
  filterItems(text: string) {
    this.userNameChanged.next(text);
    this.getItems();
  }


  getItems() {

    const params = new HttpParams()
      .set('sortName', `${this.page.orderBy}`)
      .set('sortDirection', `${this.page.orderDir}`)
      .set('pageIndex', `${this.page.pageNumber + 1}`)
      .set('pageSize', `${this.page.pageSize}`)
      .set('userId', `${this.userId ? this.userId : 0}`)
      .set('userName', `${this.userName}`)
      .set('mobileNumber', `${this.mobileNumber}`)
      .set('role', `${this.role ? this.role : 0}`)
      .set('patientType', `${this.patientType ? this.patientType : 0}`)

    this.userService.patientList(params)
      .subscribe(data => {
        debugger

        this.result = data.result.data;
        this.page.itemCount = data.result.itemCount;
        this.cdr.markForCheck();
      },


        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }

  verifieduser(id: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { Message: "Are you sure that you want to verify user?", ButtonType: DialogButtonType.YesNo }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == DialogResult.Yes) {
        this.userService.verifieduser(id)
          .subscribe(data => {
            this.toastr.info(data.result);
            this.getItems()
          },
            error => {
              this.toastr.error(error.error.responseException.exceptionMessage);
            });
      }

    })
  }
  changeUserStatus(id, isActive) {
    isActive = !isActive;
    let msg = isActive ? "Are you sure that you want to active this user?" : "Are you sure that you want to in-active this user?"
    if (confirm(msg)) {
      this.userService.verifieduser(id)
        .subscribe(data => {
          this.toastr.info(data.result);
          this.getItems()
        },
          error => {
            this.toastr.error(error.error.responseException.exceptionMessage);
          });
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(ImportPatientComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getItems();
    });
  }
  menuRolesEnable(roles = []) {
    const role = this.authService.currentUserToken.role;
    console.log(role);
    
    return roles.includes(role);
  }


}

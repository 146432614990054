import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-vaccine-progress',
  templateUrl: './vaccine-progress.component.html',
  styleUrls: ['./vaccine-progress.component.scss']
})
export class VaccineProgressComponent implements OnInit {
  regionList: any;
  selectedCity = 'New Jersey';
  data: [];
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
  };
  ColumnChart: any;
  columnChartAlbama:any;
  columnChartNewyork:any;
  constructor() {

  }

  ngOnInit() {
    this.getRegionofIncidentList();
    this.bindChartData();
    this.data = this.regionList;
    console.log("data", this.regionList)
  }
  bindChartData() {
    this.ColumnChart =null;
    if (this.selectedCity == "New Jersey") {
      this.ColumnChart = {
        chartType: 'LineChart',
        dataTable: [
          ['Week1', 'Positive Cases',' Total Vaccined'],
          ['Week1', 25000, 26000],
          ['Week2', 18000, 22000],
          ['Week3', 15000, 16000],
          ['Week4',  14000, 32000],
          ['Week5', 12000, 50000],
          ['Week6',11000, 40000],
          ['Week7',8000, 42000]
        ],
        options: {'title': '','height':400},
      };

    }
    else if (this.selectedCity == "Albama") {
      this.columnChartAlbama = {
        chartType: 'LineChart',
        height:350,
        dataTable: [
          ['Week1', 'Positive Cases',' Total Vaccined'],
          ['Week1', 21000, 23000],
          ['Week2', 22000, 24000],
          ['Week3', 23022, 25000],
          ['Week4',  24000, 26000],
          ['Week5', 25000, 27000],
          ['Week6',40000, 42000],
          ['Week7',60000, 62000]
        ],
        options: {'title': '','height':400}
      };
    }
    else if (this.selectedCity == "Newyork") {
      this.columnChartNewyork = {
        height:350,
        chartType: 'LineChart',
        dataTable: [
          ['Week1', 'Positive Cases',' Total Vaccined'],
          ['Week1', 27000, 29000],
          ['Week2', 28000, 30000],
          ['Week3', 19022, 21000],
          ['Week4',  30000, 32000],
          ['Week5', 31000, 33000],
          ['Week6',27000, 29000],
          ['Week7',28000, 30000]
        ],
        options: {'title': '','height':400}
      };
    }
    
  }
  
  onChangeEvent(city) {
    this.bindChartData();
    this.getRegionofIncidentList();
  }
  getRegionofIncidentList() {
    if(this.selectedCity == "New Jersey"){
    this.regionList = [
      {
        week: 'Week1',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '25000',
        vaccinedPerson: '26000'
      },
      {
        week: 'Week2',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '18000',
        vaccinedPerson: '22000'
      },
      {
        week: 'Week3',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '15000',
        vaccinedPerson: '16000'
      },
      {
        week: 'Week4',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '14000',
        vaccinedPerson: '32000'
      },
      {
        week: 'Week5',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '12000',
        vaccinedPerson: '50000'
      },
     
      {
        week: 'Week6',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '11000',
        vaccinedPerson: '40000'
      },
      {
        week: 'Week7',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '8000',
        vaccinedPerson: '42000'
      },
      
      
    ];
  }
  else if(this.selectedCity == "Albama"){
    this.regionList = [
      {
        week: 'Week1',
        city: 'Albama',
        totalTest: '2020120',
        positiveCases: '21000',
        vaccinedPerson: '23000'
      },
      {
        week: 'Week2',
        city: 'Albama',
        totalTest: '2020120',
        positiveCases: '22000',
        vaccinedPerson: '24000'
      },
      {
        week: 'Week3',
        city: 'Albama',
        totalTest: '2020120',
        positiveCases: '23022',
        vaccinedPerson: '25000'
      },
      {
        week: 'Week4',
        city: 'Albama',
        totalTest: '2020120',
        positiveCases: '24000',
        vaccinedPerson: '26000'
      },
      {
        week: 'Week5',
        city: 'Albama',
        totalTest: '2020120',
        positiveCases: '25000',
        vaccinedPerson: '27000'
      },
      {
        week: 'Week6',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '40000',
        vaccinedPerson: '42000'
      },
      {
        week: 'Week7',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '60000',
        vaccinedPerson: '62000'
      },
      
      
    ];
  }
  else if(this.selectedCity == "Newyork"){
    this.regionList = [
      {
        week: 'Week1',
        city: 'New York',
        totalTest: '2020120',
        positiveCases: '27000',
        vaccinedPerson: '29000'
      },
      {
        week: 'Week2',
        city: 'New York',
        totalTest: '2020120',
        positiveCases: '28000',
        vaccinedPerson: '30000'
      },
      {
        week: 'Week3',
        city: 'New York',
        totalTest: '2020120',
        positiveCases: '28000',
        vaccinedPerson: '30000'
      },
      {
        week: 'Week4',
        city: 'New York',
        totalTest: '2020120',
        positiveCases: '19022',
        vaccinedPerson: '21000'
      },
      {
        week: 'Week5',
        city: 'New York',
        totalTest: '2020120',
        positiveCases: '30000',
        vaccinedPerson: '32000'
      },
      {
        week: 'Week6',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '27000',
        vaccinedPerson: '29000'
      },
      {
        week: 'Week7',
        city: 'New Jersey',
        totalTest: '2020120',
        positiveCases: '28000',
        vaccinedPerson: '30000'
      },
      
    ];
  }
  }
}




import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/AuthService';
import { egretAnimations } from 'app/shared/animations/egret-animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [egretAnimations]
})
export class DashboardComponent implements OnInit {
  panelOpenState = false;
  locChartToggle = false;

  currentUser: null;
  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  menuRolesEnable(roles = []) {
    const role = this.authService.currentUserToken.role;
    console.log(role);
    
    return roles.includes(role);
  }

}

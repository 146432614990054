import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SymptomchekerService } from 'app/services/symptomchekerService';
@Component({
  selector: 'app-symptom-cheker-details-by-user-id',
  templateUrl: './symptom-cheker-details-by-user-id.component.html',
  styleUrls: ['./symptom-cheker-details-by-user-id.component.scss']
})
export class SymptomChekerDetailsByUserIdComponent implements OnInit {
  userNameChanged: Subject<string> = new Subject<string>();
  result: any[];
  userId: any;
  userName = '';
  constructor(private route: ActivatedRoute, cdr: ChangeDetectorRef, private symptomchekerService: SymptomchekerService, private toastr: ToastrService) { }
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
    orderBy: 'FirstName',
    orderDir: 'asc'
  };
  ngOnInit() {
    if (this.route.snapshot.paramMap.get('userId')) {
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    this.getItems();
  }
  onPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getItems();
  }
  onSort(sortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getItems();
  }
  filterItems(text: string) {
    this.userNameChanged.next(text);
    this.getItems();
  }
  getItems() {

    const params = new HttpParams()
      .set('sortName', `${this.page.orderBy}`)
      .set('sortDirection', `${this.page.orderDir}`)
      .set('pageIndex', `${this.page.pageNumber + 1}`)
      .set('pageSize', `${this.page.pageSize}`)
      .set('userId', `${this.userId?this.userId: 0}`)
      .set('userName', `${this.userName}`);
    //this.userService.getallUsers(params)
    this.symptomchekerService.GetSymtompListByUserId(this.userId)
      .subscribe(data => {
        debugger;
        this.result = data.result.data;
        this.page.itemCount = data.result.itemCount;
        //this.cdr.markForCheck();
      },


        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }

}

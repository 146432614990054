import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserProfile } from 'app/models/userProfile';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/AuthService';
import { ProfileService } from 'app/services/ProfileService';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { isSuccess } from 'angular-in-memory-web-api';
import { Router } from '@angular/router';
import { constants } from 'os';

@Component({
  selector: 'app-change-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  profileForm: FormGroup
  userProfile: UserProfile;
  error: string;
  id: number;
  specialities = [];
  constructor(private router: Router, private ref: ChangeDetectorRef, private toastr: ToastrService, private authService: AuthService, private profileService: ProfileService) {

  }

  ngOnInit() {
    
    this.getDoctorSpecialities();
    this.profileForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      mobileNumber: new FormControl([Validators.pattern('^[0-9 ]*$')]),
      email: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      licenceNumber: new FormControl(''),
      department: new FormControl(''),
      speciality: new FormControl('')

    });

    // const role = this.authService.currentUserToken.role

    // if (role === 'Doctor') {
    //   this.profileForm.get('licenseNumber').setValidators([Validators.required])
    //   this.profileForm.get('speciality').setValidators([Validators.required])
    // } else {
    //   this.profileForm.get('licenseNumber').clearValidators();
    //   this.profileForm.get('speciality').clearValidators();
    // }
    // if (role === 'DigitalOfficer') {      
    //   this.profileForm.get('department').setValidators([Validators.required])
    // } else {

    //   this.profileForm.get('department').clearValidators();
    // }

    this.loadProfile();
  }
  menuRolesEnable(roles = []) {
    const role = this.authService.currentUserToken.role;
    console.log(role);

    return roles.includes(role);
  }

  loadProfile(refreshToken = false) {

    this.profileService.getProfile()
      .pipe(first())
      .subscribe(data => {
        debugger;
              this.userProfile = data.result;
        console.log('User Profile: ',this.userProfile);
        this.userProfile.profilePicUrl = this.userProfile.profilePicUrl || "assets/images/blank-profile.png"
debugger;
        console.log(this.userProfile)
        this.profileForm.patchValue({
          //id:this.userProfile.id,
          firstName: this.userProfile.firstName,
          lastName: this.userProfile.lastName,
          //mobileNumber: this.userProfile.mo,
          email: this.userProfile.email,
          address: this.userProfile.address,
          licenceNumber: this.userProfile.licenceNumber,
          department: this.userProfile.department,
          speciality: this.userProfile.speciality
          //dob: this.userProfile.dob,
          //address1: this.userProfile.address1,
          //address2: this.userProfile.address2,
          //faxNumber: this.userProfile.faxNumber,
          //ssn: this.userProfile.ssn,
          //npi: this.userProfile.npi,
          //state: this.userProfile.state,
          //city: this.userProfile.city,
          // age: this.userProfile.age,
          //zipcode: this.userProfile.zipcode,

          // location: this.userProfile.location,
          // description: this.userProfile.description
        });
        if (refreshToken) {
          this.authService.refreshUserToken()
          this.ref.markForCheck()
        }
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }

  onSubmit() {
    debugger;
    this.submitButton.disabled = true;
    // this.progressBar.mode = 'indeterminate';
    const profileData = this.profileForm.value;
    console.log(profileData);
    // this.profileService.updateProfile(this.authService.currentUserToken.id, profileData.firstName, profileData.lastName, "", profileData.phoneNumber, profileData.dob, profileData.address1, profileData.city,profileData.state,profileData.zipcode,profileData.age)
    this.profileService.updateProfile(this.authService.currentUserToken.id, profileData.firstName, profileData.lastName, profileData.email, profileData.address, profileData.licenceNumber, profileData.department, profileData.speciality)
      .subscribe(data => {
        // this.toastr.info(data.result);
        // this.loadProfile(true);
        // this.submitButton.disabled = false;
        // this.progressBar.mode = 'determinate';
        // let data = JSON.parse(res);
        // if (!data.isError) {
        //   this.toastr.info(data.result);
        //   //this.loadProfile(true);
        //   this.router.navigate(["/signin"]);
        this.toastr.info(data.result);
        this.loadProfile(true);
        this.router.navigate(["/dashboard"]);
      },
        // else {
        //   this.toastr.error(data.responseException.exceptionMessage);
        // }
        //},
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          this.progressBar.mode = 'determinate';
        });
  }

  getDoctorSpecialities() {
    this.profileService.getDoctorSpeciality()
      .subscribe(
        data => {
          debugger;
          this.specialities = data.result;
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }
}

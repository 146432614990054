import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Roles } from 'app/helpers/roles';
import { AccountService } from 'app/services/AccountService';
import { AuthService } from 'app/services/AuthService';
import { CoronapatienttravellhistoryService } from 'app/services/coronapatienttravellhistoryService';
import { ToastrService } from 'ngx-toastr';
//import * as XLSX from 'xlsx';
type AOA = any[][];
@Component({
  selector: 'app-import-patient',
  templateUrl: './import-patient.component.html',
  styleUrls: ['./import-patient.component.scss']
})
export class ImportPatientComponent implements OnInit {

  selectedItems: any = [];
  responseItems: any = [];
  pageMode: any = 0;
  currentUserRole: string;
  constructor(private route: ActivatedRoute,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private accountService: AccountService,
    private coronapatienttravellhistoryService: CoronapatienttravellhistoryService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ImportPatientComponent>) { }

  ngOnInit() {
    this.currentUserRole = this.authService.currentUserToken.role;
  }

  onFileSelect(files: FileList) {
    // console.log(files);
    if (files && files.length > 0) {

      let file: File = files.item(0);
      // this.fileInfo.Name = file.name;
      // this.fileInfo.Size = file.size;
      //  console.log(file.type);
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let res: string = reader.result as string;
        const lines = res.split('\n'); // Splits you file into lines
        const columns = [];
        lines.forEach((line, index) => {
          if (index == 0) {
            columns.push(...line.split(',')); // Get first item of line
          }
          else {
            let rowData = [...line.split(',')];
            if (rowData && rowData.length > 4) {
              let d = {
                FirstName: rowData[0],
                LastName: rowData[1],
                MobileNumber: rowData[2],
                CovidStatus: (rowData[3].toLowerCase() == "yes") ? 1 : 0,
                EmailId: rowData[4],
                Status: false,
                PatientType: this.currentUserRole == "DigitalOfficer" ? 2 : 3
              };
              this.selectedItems.push(d);
            }
          }
        });
        // this.ddlEditorDataSource = columns.filter(a => a);
        // console.log(csv);
      }
    }
  }



  importData() {
    if (this.selectedItems.length > 0) {
      this.accountService.importPatients(this.selectedItems)
        .subscribe(
          data => {
            this.toastr.info(data.result.message);
            //let failureCount = data.result.list.filter(a => a.status == false).length;
            if (data.result && data.result.list) {
              this.responseItems = data.result.list;
              this.pageMode = 1;
              // this.dialogRef.close();
            }


            // if (failureCount > 0) {
            //   this.pageMode = 1;
            // }
            // this.dialogRef.close();
          },
          error => {
            this.toastr.error(error.error.responseException.exceptionMessage);
          });
    }
    else {
      this.toastr.error("No data found. Please select valid file with data.");
    }
  }

}

import { Injectable } from '@angular/core';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ExportModel } from 'app/models/export.model';
import { ExportType } from 'app/helpers/app-enums';
import { debug } from 'console';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  apiUrl: string;
  imageBasePath: string;

  constructor(private http: HttpClient, public datepipe: DatePipe) { }

  public export(model: ExportModel) {

    if (model.type == ExportType.PDF) {
      this.exportAsPdfFile(model);
    }
    else if (model.type == ExportType.Excel) {
      // this.exportAsExcelFile(model);
    }
  }

  public exportAsPdfFile(model: ExportModel): void {
    try {
      var doc = new jsPDF();
      // var col = model.columnNames;
      // var rows = [];
      // model.json.forEach(element => {
      //   rows.push(element);
      // });
      // doc.autoTable(col, rows, {
      //   theme: 'grid',
      //   styles: { fillColor: '#fff' },
      //   headerStyles: { fillColor: '#405467', textColor: 'white' },
      //   beforePageContent: function (data) {
      //     doc.text(model.reportHeader, 20, 10);
      //   },
      //   margin: [20, 10, 20, 10]
      // });
      debugger;
      autoTable(doc, {
        
        columns: model.columnNames,
        body: model.json,
        theme:"grid",
        showHead:true,
        // beforePageContent: function (data) {
        //    doc.text(model.reportHeader, 20, 10);
        //  },
      })
      const addHeaderFooter = doc => {
        const pageCount = doc.internal.getNumberOfPages()
      
        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
            align: 'center'
          })
          doc.text(model.reportTitle,  doc.internal.pageSize.width / 2, 10, {
            align: 'center',
            font_size:"50px"
          })
        }
      }
      
      addHeaderFooter(doc);
      doc.save(`${model.reportTitle}.pdf`);
      // model.callback();
    }
    catch {
      // if (model.callback) {
      //   model.callback();
      // }
    }
  }

}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/services/AuthService';
import { ProfileService } from 'app/services/ProfileService';
import { UserProfile } from 'app/models/userProfile';
import { MatProgressBar } from '@angular/material';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;

  public uploader: FileUploader;
  userProfile: UserProfile;
  isReload = true;
  error: string;

  constructor(private toastr: ToastrService, private cdr: ChangeDetectorRef, private authService: AuthService, private profileService: ProfileService) {

  }

  ngOnInit() {
    this.loadProfile();
    const data = { Id: this.authService.currentUserToken.id };
    this.uploader = new FileUploader({
      url: `${environment.apiURL}/profile/uploadprofilepicture`,
      autoUpload: true,
      allowedFileType: ['image'],
      additionalParameter: data,
      method: 'put',
      authToken: `Bearer ${this.authService.currentUserToken.token}`,
    });
    //Important
    this.uploader.onAfterAddingFile = (file => {
      this.progressBar.mode = 'query';
      file.withCredentials = false;
    });

    this.uploader.response.subscribe(res => {
      // Upload returns a JSON on success  
      let data = JSON.parse(res);
      if (!data.isError) {
        this.toastr.info(data.result);
        this.loadProfile(true);
      }
      else {
        this.toastr.error(data.responseException.exceptionMessage);
      }
      this.progressBar.mode = 'determinate';
    });

    this.authService.currentUser.subscribe(() => {
      if (this.isReload) {
        this.loadProfile();
      }
    });

  }

  loadProfile(refreshToken = false) {
    this.profileService.getProfile()
      .subscribe(
        data => {
          this.userProfile = data.result;
          this.userProfile.profilePicUrl = this.userProfile.profilePicUrl || "assets/images/blank-profile.png"
          this.userProfile.role = this.authService.currentUserInfo.role;
          if (refreshToken) {
            this.isReload = false; // handle expected lock
            this.authService.refreshUserToken();
            this.isReload = true;
          }
          this.cdr.markForCheck();
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }
}

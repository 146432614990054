import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map, first } from 'rxjs/operators';
import { UserToken } from 'app/models/userToken';
import { UserInfo } from 'app/models/userInfo';
import { ProfileService } from './ProfileService';
import { BnNgIdleService } from 'bn-ng-idle';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<UserToken>;
    public currentUser: Observable<UserToken>;

    constructor(private http: HttpClient,private bnIdle: BnNgIdleService, private profileService: ProfileService) {
        this.currentUserSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserInfo(): UserInfo {
        return this.currentUserSubject.value;
    }

    public get currentUserToken(): UserToken {
        return this.currentUserSubject.value;
    }

    public refreshUserToken() {
        this.profileService.getProfile()
            .pipe(first())
            .subscribe(
                resp => {
                    if (!resp.isError) {
                        let userInfo = resp.result;
                        if (userInfo) {
                            let user = this.currentUserToken;
            
                            user.firstName = userInfo.firstName;
                            user.lastName = userInfo.lastName;
                            user.profilePicUrl = userInfo.profilePicUrl || 'assets/images/blank-profile.png';

                            localStorage.setItem('currentUser', JSON.stringify(user));
                            this.currentUserSubject.next(user);
                        }
                    }
                },
                error => {
                    console.log(error);
                });

        return this.http.get<any>(`${environment.apiURL}/profile/getprofile`)
            .pipe(map(resp => {
                if (!resp.isError) {
                    let userInfo = resp.result;
                    if (userInfo) {
                        let user = this.currentUserToken;

                        user.firstName = userInfo.firstName;
                        user.lastName = userInfo.lastName;
                        user.profilePicUrl = userInfo.profilePicUrl || 'assets/images/blank-profile.png';

                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }
                }
            }));
    }

    login(email: string, password: string) {
        debugger;
        return this.http.post<any>(`${environment.apiURL}/Account/Login`, { email, password })
            .pipe(map(resp => {
               debugger;
                if (!resp.isError) {
                    let user = resp.result
                    user.profilePicUrl = user.profilePicUrl || "assets/images/blank-profile.png"
                    user.password = password;
                    // login successful if there's a jwt token in the response
                    if (user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }
                    return resp;
                }
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        
    }
    isLoggedIn() {
        if(localStorage.getItem('currentUser')!=null){
           return true;
        }
         return false;
    }
}
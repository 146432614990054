import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { UserService } from 'app/services/UserService';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'app/services/SharedService';
@Component({
  selector: 'user-add-edit',
  templateUrl: './user-add-edit.component.html'
})
export class UserAddEditComponent implements OnInit {
  userForm: FormGroup;
  id: any;
  role: string;
  isEdit: any;
  stateList: any[];
  loading: boolean;
  error: string;



  constructor(private route: ActivatedRoute, private location: Location,
    private fb: FormBuilder, private toastr: ToastrService,
    private userService: UserService, private sharedService: SharedService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.route.queryParams.subscribe(params => {
      this.role = params["role"];
    });
    this.loadSateList()
    this.initializeForm();
    if (this.id) {
      this.isEdit = true;
      this.loadUser();
    }
  }
  successmsg() {
    this.toastr.success("Saved succesfully", 'Success');
  }
  loadSateList() {
    this.sharedService.getStateList().subscribe(
      data => this.stateList = data.result
    );
  }
  initializeForm() {
    this.userForm = this.fb.group({
      //userName: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      //password: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      title: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      dob: ['', Validators.required],
      ssn: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      npi: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9+]*')]],
      faxNumber: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      state: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      address1: ['', Validators.required],
      address2: [''],
    });

    if (!(this.id)) {
      this.userForm.addControl('userName', new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]));
      this.userForm.addControl('password', new FormControl('', Validators.required));
    }
  }

  loadUser() {
    this.loading = true;
    this.userService.getById(this.id)
      .pipe(first())
      .subscribe(data => {
        this.userForm.patchValue(data.result)
        console.log(data.result);
        this.loading = false;
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.loading = false;
        });
  }

  submitForm() {
debugger;
    if (this.userForm.valid) {
      console.log(this.userForm.value);
      this.error = null;
      if (this.isEdit) {
        this.loading = true;
        var formValue = this.userForm.value;
        formValue.id = this.id;
        this.userService.updateUser(formValue)
          .subscribe(data => {
            this.toastr.info(data.result);
            this.location.back();
            this.loading = false;
          },
            error => {
              this.toastr.error(error.error.responseException.exceptionMessage);
              this.loading = false;
            });
      }
      else {
        this.loading = true;
        var formValue = this.userForm.value;
        formValue.role = this.role;
        this.userService.addUser(this.userForm.value)
          .subscribe(data => {
            this.toastr.info(data.result);
            this.location.back();
            this.loading = false;
          },
            error => {
              this.toastr.error(error.responseException.exceptionMessage);
              this.loading = false;
            });
      }
    }
  }
}
import { Component, Inject, OnInit } from '@angular/core';

import { MouseEvent } from '@agm/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'assets/examples/material/basic-dialog/basic-dialog.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss']
})
export class GooglemapComponent implements OnInit {
 
  yt ='';
  dialogData:any={};
  constructor(private sanitizer: DomSanitizer,@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.dialogData=data;
  }
  

  ngOnInit() {
    this.yt = `<iframe style="
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    zoom="25"
    src="https://maps.google.com/maps?q=${this.dialogData["lat"]},	${this.dialogData["long"]}&hl=es&z=14&amp;output=embed">
    </iframe>`;

  }
  

}

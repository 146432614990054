import { Component, OnInit,ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { SymptomchekerService } from 'app/services/SymptomchekerService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButton, MatProgressBar } from '@angular/material';
import { AuthService } from 'app/services/AuthService';
import { Roles } from 'app/helpers/roles';
@Component({
  selector: 'app-symptomcheckerscore',
  templateUrl: './symptomcheckerscore.component.html',
  styleUrls: ['./symptomcheckerscore.component.scss']
})
export class SymptomcheckerscoreComponent implements OnInit {
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  CreateNewSymtom: FormGroup
  error: string;
  patientId: any;
  patientName: any;
  currentUserId: any;
  currentUserRole: any;

  constructor(private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService,
    private symptomchekerService: SymptomchekerService,
    private authService: AuthService) { }

  ngOnInit() {
    this.currentUserRole = this.authService.currentUserToken.role;
    this.patientId = this.route.snapshot.paramMap.get('id');
    this.initForm();
  }
  initForm() {
    this.CreateNewSymtom = new FormGroup({
      //UserId: new FormControl(this.patientId),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      emailId: new FormControl(''),
      phoneNumber: new FormControl('', [Validators.required]),
      //cityId: new FormControl(''),
      //stateId: new FormControl(''),
      zipCode: new FormControl(''),
      address1: new FormControl(''),
      gender:new FormControl(''),
      ageRange:new FormControl(''),
 
     
     
    })
  }
  savePatient() {
    const data = this.CreateNewSymtom.value;
    data.PatientType=this.currentUserRole == "DigitalOfficer" ? 2 : 3
    //this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    this.symptomchekerService.addNewPatient(data)

      .subscribe(
        data => {
          debugger;
          //this.toastr.info(data.result);
          //console.log(data.result)
          //this.router.navigate(["/symptomchecker"]);
          //this.router.navigate([`/symptomchecker/data.result.userId`]);
          this.router.navigate(['symptomchecker', data.result.userId]);
        },
        error => {
          debugger;
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          //this.progressBar.mode = 'determinate';
        });
  }
}

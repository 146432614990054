import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoronapatienttravellhistoryService {

  constructor(private http: HttpClient) { }
  
  gettravelhistory() {
    return this.http.get<any>(environment.travelhistory);
  }
  GetAllUserTrackingHistory(params) {
    return this.http.get<any>(`${environment.apiURL}/GeoLocationLog`,{ params});
  }
}

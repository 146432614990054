import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covidapprovedvaccines',
  templateUrl: './covidapprovedvaccines.component.html',
  styleUrls: ['./covidapprovedvaccines.component.scss']
})
export class CovidapprovedvaccinesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

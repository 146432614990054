import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-regionof-incidentchart',
  templateUrl: './regionof-incidentchart.component.html',
  styleUrls: ['./regionof-incidentchart.component.scss']
})
export class RegionofIncidentchartComponent implements OnInit {
  public geoChart: GoogleChartInterface = {
    chartType: 'BarChart',
    dataTable: [
      
      ['Number of Test in %','Number of Test in %'],
          ['Week1',48],
          ['Week2', 50],
          ['Week3',41 ],
          ['Week4', 40 ],
          ['Week5', 2],
          ['Week6',60],
          ['Week7',51]
    ],
    //firstRowIsData: true,
    options: {'title': '','height':400},
  };
  constructor() { }

  ngOnInit() {
   
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ExportModel } from 'app/models/export.model';
import { ExportService } from 'app/services/export.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/services/userService';
import { DatePipe } from '@angular/common';
import { DialogButtonType, DialogResult } from 'app/helpers/app-enums';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userNameChanged: Subject<string> = new Subject<string>();
  data: [];
  pdfData: any;
  pdfDoc = new jsPDF();
  result: any[];
  userId: any;
  userName = '';
  mobileNumber = '';
  exportModel: ExportModel = new ExportModel();
  role = '';
  page = {
    pageNumber: 0,
    itemCount: 0,
    pageSize: 10,
    orderBy: 'FirstName',
    orderDir: 'asc'
  };

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef, private userService: UserService, private toastr: ToastrService,
    private exportService: ExportService, 
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {

  }



  ngOnInit() {
    if (this.route.snapshot.paramMap.get('userId')) {
      this.userId = this.route.snapshot.paramMap.get('userId');
    }
    this.getItems();

  }
  clear() {
    this.userName = '';
    this.role = '';
    this.mobileNumber = '';
    this.page = {
      pageNumber: 0,
      itemCount: 0,
      pageSize: 10,
      orderBy: '',
      orderDir: ''
    };

    this.getItems();
  }

  onPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getItems();
  }
  onSort(sortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getItems();
  }
  filterItems(text: string) {
    this.userNameChanged.next(text);
    this.getItems();
  }


  getItems(isForPDF = false) {

    const params = new HttpParams()
      .set('sortName', `${this.page.orderBy}`)
      .set('sortDirection', `${this.page.orderDir}`)
      .set('pageIndex', `${isForPDF ? 1 : this.page.pageNumber + 1}`)
      .set('pageSize', `${isForPDF ? 10000000 : this.page.pageSize}`)
      .set('userId', `${this.userId ? this.userId : 0}`)
      .set('userName', `${this.userName}`)
      .set('role', `${this.role ? this.role : 0}`)
      .set('mobileNumber', `${this.mobileNumber}`)
    this.userService.getallUsers(params)
      .subscribe(data => {
        if (!isForPDF) {
          this.data = data.result.data;
        }
        else {
          this.pdfData = data.result.data;
          this.exportToPDFCallback();
        }

        this.result = data.result.data;
        this.page.itemCount = data.result.itemCount;
        this.cdr.markForCheck();
      },


        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }

  verifieduser(id: any) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { Message: "Are you sure that you want to verify user?", ButtonType: DialogButtonType.YesNo }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == DialogResult.Yes) {
        this.userService.verifieduser(id)
          .subscribe(data => {
            this.toastr.info(data.result);
            this.getItems()
          },
            error => {
              this.toastr.error(error.error.responseException.exceptionMessage);
            });
      }

    })
  }
  changeUserStatus1(id, isActive) {
    isActive = !isActive;
    let msg = isActive ? "Are you sure that you want to active this user?" : "Are you sure that you want to in-active this user?"
    if (confirm(msg)) {
      this.userService.changeStatus(id, isActive)
        .subscribe(data => {
          this.toastr.info(data.result);
          this.getItems()
        },
          error => {
            debugger;
            this.toastr.error(error.error.responseException.exceptionMessage);
          });
    }
  }

  changeUserStatus(id, isActive) {
    isActive = !isActive;
    let msg = isActive ? "Are you sure that you want to active this user?" : "Are you sure that you want to in-active this user?"
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
     
      //data: { Message: "Are you sure that you want to verify user?", ButtonType: DialogButtonType.YesNo }
      //data : isActive ? "Are you sure that you want to active this user?" : "Are you sure that you want to in-active this user?"
      data: {Message :msg,ButtonType: DialogButtonType.YesNo }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == DialogResult.Yes) {
        this.userService.changeStatus(id,isActive)
          .subscribe(data => {
            this.toastr.info(data.result);
            this.getItems()
          },
            error => {
              this.toastr.error(error.error.responseException.exceptionMessage);
            });
      }

    })
  }
  exportToPDF() {
    this.getItems(true);
  }
  exportToPDFCallback() {
    this.exportModel.reportTitle = "User List";
    let data = this.pdfData.map(x => ({ ...x, createdOn: this.datePipe.transform(x["createdOn"], 'MM/dd/yyyy') }));
    this.exportModel.json = data;
    this.exportModel.columnNames = [
      { header: 'First Name', dataKey: 'firstName' },
      { header: 'Last Name', dataKey: 'lastName' },
      { header: 'Email', dataKey: 'email' },
      { header: 'Mobile Number', dataKey: 'phone' },
      { header: 'User Type', dataKey: 'role' },


    ],
      this.exportService.export(this.exportModel);
  }


}



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
    constructor(private http: HttpClient) { }

    registerUser(firstName: string, lastName: string, email: string, password: string,mobileNumber:string, gender:string,address:string,role:string,
        licenceNumber:string,department:string,speciality:string
        ) {
        debugger;
       
        return this.http.post<any>(`${environment.apiURL}/account/register`, { firstName, lastName, email, password,mobileNumber,gender,address,role,licenceNumber,department,speciality });
    }
    doctorSignup(data) {
        debugger;
        return this.http.post<any>(`${environment.apiURL}/account/register`, data);
    }
    officerSignup(data) {
        debugger;
        return this.http.post<any>(`${environment.apiURL}/account/register`, data);
    }
   

    forgotPassword(email: string) {
        return this.http.post<any>(`${environment.apiURL}/account/forgotpassword`, { email });
    }
    resetPassword(email: string, password: string, confirmPassword:string, otp: string) {        
        return this.http.post<any>(`${environment.apiURL}/account/resetpassword`, { email, password, confirmPassword, otp });
    }

    // TO IMPORT PATIENTS
    importPatients(data) {
        debugger;
        return this.http.post<any>(`${environment.apiURL}/Users/CreateUserByExcelSheet`, {UserExcelList:data});
    }
}
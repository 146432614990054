import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-treatment-centers',
  templateUrl: './treatment-centers.component.html',
  styleUrls: ['./treatment-centers.component.scss']
})
export class TreatmentCentersComponent implements OnInit {
  public columChart: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['State', 'Hospitals','Dispensaries',  'Clinics', 'Pharmacies', 'Isolation Centres','Field Hospitals'],
      ['Resources',  50,40,500,700,800,300]
     
],
    
    options: {'title': '','height':500},
  };
  public columChart1: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['State', 'Nurse Assistent','Radiologists', 'Pharmacists', 'Treatment Centres', 'Physical Therapists', 'Respiratory Therapists'],
      ['Resources',  50,40,500,700,800,300]
     
],
    
    options: {'title': '','height':500},
  };
  public columChart2: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['State', 'Oxygen Concentrators',' Bipap Machines', 'Cpap MAchines', 'Nebulizers ', 'Oxygen Tanks', 'Oxygen Delivery Machines'],
      ['Resources',  50,40,500,700,800,300]
     
],
    
    options: {'title': '','height':500},
  };
  public columChart3: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['State', 'Oxygen Concentrators',' Bipap Machines', 'Cpap MAchines', 'Nebulizers ', 'Oxygen Tanks', 'Oxygen Delivery Machines'],
      ['Resources',  50,40,500,700,800,300]
     
],
    
    options: {'title': '','height':500},
  };
  
  constructor() { }

  ngOnInit() {
  }

}

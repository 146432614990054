import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SymptomchekerService {


  constructor(private http: HttpClient) { }

  getAlls() {
    return this.http.get<any>(`${environment.apiURL}/symptomchecker`);
  }
  getAll(params) {
    return this.http.get<any>(`${environment.apiURL}/symptomchecker`, { params });
  }
  GetSymtompListByUserId(userId: any) {
    debugger;
    return this.http.get<any>(`${environment.apiURL}/GetSymtompListByUserId/${userId}`);
  }
  saveSymptomCheckerData(data) {
    return this.http.post<any>(`${environment.apiURL}/SymptomChecker`, data);
  }
  filter() {
    return this.http.get<any>(`${environment.apiURL}/SymptomCheckerFillter`);
  }

  getbyid(id: any) {
    //  alert(`${environment.apiURL}/symptomchecker/${id}`);
    return this.http.get<any>(`${environment.apiURL}/symptomchecker/${id}`);
  }

  getstate() {
    return this.http.get<any>(`${environment.apiURL}/Shared/StateList`);
  }
  getcountory() {
    return this.http.get<any>(`${environment.apiURL}/Shared/CountryList`);
  }
  getcity() {
    return this.http.get<any>(`${environment.apiURL}/Shared/CityList`);
  }
  addNewPatient(data) {
    debugger;
    return this.http.post<any>(`${environment.apiURL}/Users/createPatient`, data);
}
}

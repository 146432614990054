import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { UserService } from 'app/services/UserService';
import { ToastrService } from 'ngx-toastr';
import { CoronaStatService } from 'app/services/CoronaStatService';
@Component({
  selector: 'corona-india-stat',
  templateUrl: './corona-india-stat.component.html',
  animations: egretAnimations
})
export class CoronaIndiaStatComponent implements OnInit {
  stateData: any[];
  indiaConfirmed:any;
  temp: any;
  constructor(private cdr: ChangeDetectorRef, private coronaStatService: CoronaStatService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {

    this.coronaStatService.getCoronaStats()
      .subscribe(data => {
        //const data = JSON.parse(res);
        data.forEach((state) => {
          state.confirmed = state.districtData.reduce((prev, cur) => prev + cur.confirmed, 0);
        });                
        this.stateData = data;
        this.indiaConfirmed = data.reduce((prev, cur) => prev + cur.confirmed, 0);
        this.cdr.markForCheck()
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }
}

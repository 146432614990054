import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(`${environment.apiURL}/patient/PatientDetails`);
  }
  getActiveList() {
    return this.http.get<any>(`${environment.apiURL}/patient/GetActivePatientList`);
  }
  
  getByid(id: string) {
    return this.http.get<any>(`${environment.apiURL}/Patient/${id}`);
  }

  getPatientDetail(id: string) {
    return this.http.get<any>(`${environment.apiURL}/Patient/Detail/${id}`);
  }
  updatePatientDetail(patient: any) {
    return this.http.post<any>(`${environment.apiURL}/Patient/CreateUpdateDetail`, patient);
  }
  
  createpatient(Patient) {
    return this.http.post<any>(`${environment.apiURL}/Patient`, Patient);
  }
  adddetails(Patient) {
    return this.http.post<any>(`${environment.apiURL}/Patient/PatientDetail`, Patient);
  }
  updatePatient(patient) {
    return this.http.put<any>(`${environment.apiURL}/patient/${patient.patientId}`, patient);
  }

  Deletepatient(id: string) {
    return this.http.delete<any>(`${environment.apiURL}/Patient/DeleteDetail/${id}`);
  }

}


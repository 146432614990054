import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll(userType: string = null) {
        return this.http.get<any>(`${environment.apiURL}/users?userType=${userType}`);
    }

    getById(id: number) {
        return this.http.get<any>(`${environment.apiURL}/users/${id}`);
    }

    addUser(user: any) {
        return this.http.post<any>(`${environment.apiURL}/users`, user);
    }
    updateUser(user: any) {
        return this.http.put<any>(`${environment.apiURL}/users/${user.id}`, user);
    }
    deleteUser(id: number) {
        debugger;
        return this.http.delete<any>(`${environment.apiURL}/Users/UserActive/${id}`);
    }
    getallUsers(params) {
        return this.http.get<any>(`${environment.apiURL}/Users`, { params });
    }
    patientList(params) {
        return this.http.get<any>(`${environment.apiURL}/Users/PatientList`, { params });
    }

    verifieduser(id: number) {
        debugger;
        return this.http.post<any>(`${environment.apiURL}/Users/VerifyUser/${id}?IsVerified=true`, {});
    }
    changeStatus(id, isActive) {
        return this.http.post<any>(`${environment.apiURL}/Users/Status/${id}/${isActive}`, {});
    }

}
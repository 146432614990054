import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserInfo } from 'app/models/userInfo';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  chatUserFilter: EventEmitter<UserInfo>;

  constructor(private http: HttpClient) {
    this.chatUserFilter = new EventEmitter<UserInfo>();
  }

  raiseUserChange(user: UserInfo): void {
    this.chatUserFilter.emit(user);
  }

  getChatUserList(userType: string = null) {
    return this.http.get<any>(`${environment.apiURL}/Chat/ChatUserList`);
  }

  getChatMessageList(recieverId: number = null) {
    return this.http.get<any>(`${environment.apiURL}/Chat/GetChatMessageList/${recieverId}`);
  }

  sendMessage(recieverId: number, message: string) {
    return this.http.post<any>(`${environment.apiURL}/Chat/SendChatMessage`, { recieverId: recieverId, message: message });
  }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WorldwisepatientService {

  constructor(private http: HttpClient) { }
  // getAllworldpatient(){    
  //   return this.http.get<any>(`${environment.apiURL}/api/CoronaStatWorld`);
  // }
  // getAllworldpatient() {
  //   return this.http.get<any>(environment.world);
  // }
  getWorldCovidStatus(country=''){    
    return this.http.get<any>(`${environment.covidWorldStatusURL}/${country}`);
  }  
  
}

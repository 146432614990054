export enum Role {
    User = 'User',
    Admin = 'Admin'
  }  

export enum ExportType{
    PDF=1,
    Excel=2,
}
export enum DialogType {
  Confirmation = 1,
  Alert = 2
}
export enum DialogButtonType {
  OkCancel = 1,
  YesNo = 2
}
export enum DialogResult {
  Yes = 1,
  No = 2
}
import { Component, OnInit, ViewChild, ViewChildren, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { NgForm } from "@angular/forms";
import { MessageService } from "app/services/MessageService";
import * as signalR from "@microsoft/signalr"
import { environment } from "environments/environment";
import { AuthService } from "app/services/AuthService";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-chat-contents",
  templateUrl: "./chat-contents.component.html"
})
export class ChatContentsComponent implements OnInit {
  @Input('matSidenav') matSidenav;
  @ViewChild('psContainer', { read: PerfectScrollbarDirective, static: false }) psContainer: PerfectScrollbarDirective;

  @ViewChildren("msgInput") msgInput;
  @ViewChild("msgForm", { static: false }) msgForm: NgForm;

  public user: any;
  public chatUser: any;
  public chatCollection: any[];
  loading: boolean;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public messageService: MessageService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.currentUserInfo;

    const url = environment.apiURL.replace('/api', '');
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${url}/NotificationHub`)
      .build();

    connection.start().then(function () {
      console.log('Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    connection.on("ReceiveMessage", (message) => {
      console.log(message);
      if (message.senderId == this.user.id && message.recieverId == this.chatUser.id ||
        message.senderId == this.chatUser.id && message.recieverId == this.user.id) {
        this.chatCollection.push(message);
        this.scrollToBottom();
        this.cdr.markForCheck();
      }
    });

    this.messageService.chatUserFilter
      .subscribe(data => {
        this.chatUser = data;
        this.getChatByUser();
        this.scrollToBottom();
      });
  }

  getChatByUser() {
    this.messageService.getChatMessageList(this.chatUser.id)
      .subscribe(data => {
        this.chatCollection = data.result;
        console.log(this.chatCollection);
        this.cdr.markForCheck();
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }

  sendMessage(e) {
    // console.log(this.msgForm.form.value.message)
    if (!this.msgForm.form.value.message || !this.msgForm.form.value.message.trim().length) {
      return;
    }
    const chatMessage = {
      userId: this.chatUser.id,
      text: this.msgForm.form.value.message,
      time: new Date().toISOString()
    };

    //this.chatCollection.chats.push(chat);
    this.messageService.sendMessage(chatMessage.userId, chatMessage.text)
      .subscribe(res => {
        this.initMsgForm();
        this.cdr.markForCheck();
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }

  initMsgForm() {
    setTimeout(() => {
      this.msgForm.reset();
      this.msgInput.first.nativeElement.focus();
      this.scrollToBottom();
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.psContainer) {
        this.psContainer.update();
        this.psContainer.scrollToBottom(0, 400);
      }
    });
  }
}

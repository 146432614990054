import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-vacinprogress-chart',
  templateUrl: './vacinprogress-chart.component.html',
  styleUrls: ['./vacinprogress-chart.component.scss']
})
export class VacinprogressChartComponent implements OnInit {
  public barChart: GoogleChartInterface = {
    chartType: 'BarChart',
    dataTable: [
      ['Week1', 'No Of VaccineCenter'],
      ['New Jersey', 50],
      ['Albama', 60],
      ['NewYork', 85]
      
    ],
    //firstRowIsData: true,
    options: {'title': ''},
  };
  public barChart1: GoogleChartInterface = {
    chartType: 'BarChart',
    dataTable: [
      ['Week1', 'No Of Personnel'],
      ['Site1', 20000],
      ['Site2', 30000],
      ['Site3', 40000]
      
    ],
    //firstRowIsData: true,
    options: {'title': ''},
  };
  constructor() { }

  ngOnInit() {
   
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }

  getStateList() {
    return this.http.get<any>(`${environment.apiURL}/Shared/StateList`);
  }
  getLevelOfCareList(searchText:string) {
    return this.http.get<any>(`${environment.apiURL}/Shared/LevelOfCareCode?searchText${searchText}`);
  }
}

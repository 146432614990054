import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { SymptomchekerService } from 'app/services/symptomchekerService';


import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-symptomchekerdetails',
  templateUrl: './symptomchekerdetails.component.html',
  styleUrls: ['./symptomchekerdetails.component.scss']
})
export class SymptomchekerdetailsComponent implements OnInit {
  id: any;

  Symptomcheker: any;
  loading: boolean;
  error: any;
  score: any;
  values: any;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef, private symptomchekerService: SymptomchekerService, private toastr: ToastrService) { }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    this.getItems();
  }
  

  GetSymptomScoreValue(getvalue) {
    let text = "None";
    switch (getvalue) {
      case "5":
        text = "Low"
        break;
      case "10":
        text = "Medium"
        break;
      case "15":
        text = "High"
        break;
      default:
        text = "None"
        break;
    }
    return text;
  }
  getItems() {
    this.loading = true;
    this.symptomchekerService.getbyid(this.id)
      .subscribe(data => {
        debugger;
        this.Symptomcheker = data.result;
        console.log(this.Symptomcheker);
        this.loading = false;
        this.cdr.markForCheck();
        this.GetSymptomScoreValue(this.Symptomcheker)
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }

}

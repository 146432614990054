import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-postivity-rate',
  templateUrl: './postivity-rate.component.html',
  styleUrls: ['./postivity-rate.component.scss']
})
export class PostivityRateComponent implements OnInit {
  public geoChart: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['Region Of Incident', 'No Of Test','No Of Positive'],
      ['NewYork',5000000,4000000],
      ['NewJersy',31000,20000],
      ['Alaska',  8000000,1000000],
      ['Albama ', 500000,5000],
      ['Hawaii', 57000,4000]
    ],
    //firstRowIsData: true,
    options: {'title': ''},
  };
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'app/services/AuthService';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent implements OnInit {
  //@ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  signinForm: FormGroup;
  returnUrl: string;
  error: string;
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private authService: AuthService) {
  }

  ngOnInit() {
    //To logout from app
    this.authService.logout();

    this.signinForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      //rememberMe: new FormControl(false) // not required
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  signin() { 
    debugger;   
    const signinData = this.signinForm.value    
    this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    this.authService.login(signinData.email, signinData.password)
      .pipe(first())
      .subscribe(data => {
        this.router.navigate([this.returnUrl]);
      },
        error => {
          console.log(error);
          
          this.toastr.error(error.error.responseException.exceptionMessage)
          this.submitButton.disabled = false;
          //this.progressBar.mode = 'determinate';
        });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'used-levelofcare-chart',
  templateUrl: './used-levelofcare-chart.component.html'
})
export class UsedLevelofcareChartComponent implements OnInit {
  chartType: any;
  chartOptions: any;
  chartColors: any;
  labels: any;
  data: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.initilizeChart();
  }

  initilizeChart() {
    this.chartType = 'doughnut';

    this.chartOptions = {
      legend: { display: false }

    };

    this.chartColors = [{ backgroundColor: ['#ffa500', '#27a9d8', '#00bdaa','#f95d6a','#00a8cc','#05dfd7','#9399ff'] } ];
    this.labels = ['Consultation Service', 'Subsequent Care', 'Discharge Care Service', 'Critical Care Service', 'H & P', 'Observation Service', 'Advance Care Planning'];
    this.data = [[350, 450, 100, 350, 450, 100, 100]];
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
}

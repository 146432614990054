import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatProgressBar, MatSnackBar } from '@angular/material';
import { AuthService } from 'app/services/AuthService';
import { ProfileService } from 'app/services/ProfileService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  passwordForm: FormGroup;
  error: string;

  constructor(private router: Router, private toastr: ToastrService, private authService: AuthService, private profileService: ProfileService) { }

  ngOnInit() {
    const oldPassword = new FormControl('', Validators.required);
    const newPassword = new FormControl('', Validators.required);
    const confirmPassword = new FormControl('', CustomValidators.equalTo(newPassword));

    this.passwordForm = new FormGroup({
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    });
  }

  submitPassword() {
    debugger;
    const passwordData = this.passwordForm.value;
    console.log(passwordData);

    this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    this.profileService.changePassword(this.authService.currentUserToken.id, passwordData.oldPassword, passwordData.newPassword)      
      .subscribe(data => {
        this.toastr.info(data.result);
        this.router.navigate(["/dashboard"]);
      },
        error => {
          debugger;
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          this.progressBar.mode = 'determinate';
        });
  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { UserService } from 'app/services/UserService';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'doctor-list',
  templateUrl: './doctor-list.component.html',
  animations: egretAnimations
})
export class DoctorListComponent implements OnInit {

  items: any[];
  temp: any[];
  loading: boolean;
  error: string;

  constructor(private cdr: ChangeDetectorRef, private userService: UserService, private toastr: ToastrService) { }

  ngOnInit() {
    this.loading = false;
    this.getItems();
  }

  getItems() {
    this.loading = true;
    this.userService.getAll('doctor')
      .pipe(first())
      .subscribe(data => {        
          this.items = this.temp = data.result;
          this.loading = false;
          this.cdr.markForCheck();
        },
        error=> {
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.loading = false;
      });
  }

  filterItems(event) {
    const val = event.target.value.toLowerCase();
    this.items = this.temp.filter(singleItem =>
      singleItem['lastName'].toString().toLowerCase().includes(val) ||
      singleItem['firstName'].toString().toLowerCase().includes(val)
    );
  }

  onDelete(id: number) {
    this.loading = true;
    this.userService.deleteUser(id)
      .subscribe(data => {        
          this.toastr.info(data.result);
          this.getItems();
        },
        error=> {
          this.toastr.error(error.error.responseException.exceptionMessage);        
      });
  }
}


import { ExportType } from "app/helpers/app-enums";

export class ExportModel {
    public type: ExportType=ExportType.PDF;
    public json: any[];
    public reportTitle: string;
    public columnNames: ExportColumns[]=[];
}
export class ExportColumns{
    public header: string;
    public dataKey: string;
    // public width: string;
}

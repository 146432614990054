import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { AccountService } from 'app/services/AccountService';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { ProfileService } from 'app/services/ProfileService';

@Component({
  selector: 'app-doctor-signup',
  templateUrl: './doctor-signup.component.html',
  styleUrls: ['./doctor-signup.component.scss']
})
export class DoctorSignupComponent implements OnInit {
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  doctorsignupForm: FormGroup
  error: string;
  specialities = [];
  constructor(private router: Router, private toastr: ToastrService, private accountService: AccountService, private profileService: ProfileService) { }

  ngOnInit() {
    this.initForm();
   
    this.getDoctorSpecialities();
  }
  initForm() {
    const password = new FormControl('', Validators.required);
    const confirmPassword = new FormControl('', CustomValidators.equalTo(password));
    this.doctorsignupForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      mobileNumber: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      role: new FormControl('Doctor'),
      licenceNumber: new FormControl(),
      department: new FormControl(),
      speciality: new FormControl(),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: password,
      confirmPassword: confirmPassword,

    })
  }
  getDoctorSpecialities() {
    this.profileService.getDoctorSpeciality()
      .subscribe(
        data => {
          debugger;
          this.specialities = data.result;
        },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });
  }


  signup() {
    debugger;
    const data = this.doctorsignupForm.value;
    console.log(data);
   

    this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    this.accountService.doctorSignup(data)
      .subscribe(
        data => {
          debugger;
          this.toastr.info(data.result);
          this.router.navigate(["/signin"]);
        },
        error => {
          debugger;
          this.toastr.error(error.error.responseException.exceptionMessage);
          this.submitButton.disabled = false;
          //this.progressBar.mode = 'determinate';
        });
  }


}

import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MessageService } from "app/services/MessageService";
import { AuthService } from "app/services/AuthService";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-chat-left-sidenav",
  templateUrl: "./chat-left-sidenav.component.html"
})
export class ChatLeftSidenavComponent implements OnInit {
  isSidenavOpen = true;

  user: any;
  userList: any[];

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.user = this.authService.currentUserInfo;
    this.messageService.getChatUserList()
      .subscribe(data => {
        this.userList = data.result
        this.cdr.markForCheck();
      },
        error => {
          this.toastr.error(error.error.responseException.exceptionMessage);
        });

  }

  updateChatUser(user) {
    this.messageService.raiseUserChange(user)
  }
}

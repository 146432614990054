import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whocbc',
  templateUrl: './whocbc.component.html',
  styleUrls: ['./whocbc.component.scss']
})
export class WhocbcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
@Component({
  selector: 'app-lockdown-movement',
  templateUrl: './lockdown-movement.component.html',
  styleUrls: ['./lockdown-movement.component.scss']
})
export class LockdownMovementComponent implements OnInit {
  selectedCity = 'New Jersey';
 
  barChart: any;
  barChartAlbama:any;
  barChartNewyork:any;
  constructor() { }

  ngOnInit() {
    this.bindChartData();
  }
  bindChartData() {
    this.barChart =null;
    if (this.selectedCity == "New Jersey") {
      this.barChart = {
        chartType: 'ColumnChart',
        dataTable: [
          ['Day', 'No in self isolation','No in quarantine', 'No violating Quarantine'],
          ['Day1', 100,60, 40],
          ['Day2', 200,150, 50],
          ['Day3', 300,200, 100],
          ['Day4', 400,300, 100],
          ['Day5',  500,300, 200],
          ['Day6', 600,400, 200],
          ['Day7', 700,500, 200]
         
        ],
        options: {'title': '','height':500},
      };

    }
    else if (this.selectedCity == "Albama") {
      this.barChartAlbama = {
        chartType: 'ColumnChart',
        height:350,
        dataTable: [
          ['Day', 'No in self isolation','No in quarantine', 'No violating Quarantine'],
          ['Day1', 200,100, 100],
          ['Day2', 300,200, 100],
          ['Day3', 400,300, 100],
          ['Day4', 500,300, 100],
          ['Day5',  600,400, 200],
          ['Day6', 700,500, 200],
          ['Day7', 800,600, 200]
        ],
        options: {'title': '','height':500}
      };
    }
    else if (this.selectedCity == "Newyork") {
      this.barChartNewyork = {
        height:350,
        chartType: 'ColumnChart',
        dataTable: [
          ['Day', 'No in self isolation','No in quarantine', 'No violating Quarantine'],
          ['Day1', 400,200, 200],
          ['Day2', 500,300, 200],
          ['Day3', 600,500, 100],
          ['Day4', 700,600, 100],
          ['Day5',  800,600, 200],
          ['Day6', 900,800, 100],
          ['Day7', 1000,850, 150]
         
        ],
        options: {'title': '','height':500}
      };
    }
  }
  onChangeEvent(city) {
    this.bindChartData();
    
  }

}

import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatDialogModule, MatStepperModule, MatDialogRef, MAT_DIALOG_DATA, MatTableModule, MatOptionModule } from '@angular/material';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ChartsModule } from 'ng2-charts';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SigninComponent } from './common/signin/signin.component';
import { SignupComponent } from './common/signup/signup.component';
import { SharedMaterialModule } from './shared/shared-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorComponent } from './common/error/error.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { ProfileComponent } from './common/profile/profile.component';

import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChangePasswordComponent } from './common/profile/change-password/change-password.component';
import { EditProfileComponent } from './common/profile/edit-profile/edit-profile.component';
import { PatientService } from './services/PatientService';
import { ToastrModule } from 'ngx-toastr';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
// import { SuperbillSubmittedListComponent } from './pages/superbills/superbill-submitted-list/superbill-submitted-list.component';
// import { SuperbillProcessedListComponent } from './pages/superbills/superbill-processed-list/superbill-processed-list.component';
import { NgChartjsModule } from 'ng-chartjs';
import { NewPatientChartComponent } from './pages/dashboard/new-pateint-chart/new-pateint-chart.component';
import { TotalPatientChartComponent } from './pages/dashboard/total-pateint-chart/total-pateint-chart.component';
import { UsedLevelofcareChartComponent } from './pages/dashboard/used-levelofcare-chart/used-levelofcare-chart.component';
import { SuperbillProcessedChartComponent } from './pages/dashboard/superbill-processed-chart/superbill-processed-chart.component';
import { SuperbillSubmittedChartComponent } from './pages/dashboard/superbill-submitted-chart/superbill-submitted-chart.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { AppChatsModule } from './pages/messaging/app-chats.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DoctorListComponent } from './pages/users/doctor-list/doctor-list.component';
import { MedicalOfficerListComponent } from './pages/users/medical-officer-list/medical-officer-list.omponent';
import { UserAddEditComponent } from './pages/users/user-add-edit/user-add-edit.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { FormDirective } from './helpers/form.directive';
import { CoronaIndiaStatComponent } from './pages/corona-stat/corona-india-stat/corona-india-stat.component';
import { CoronaStateStatComponent } from './pages/corona-stat/corona-state-stat/corona-state-stat.component';

import { SymptomschekerComponent } from './pages/symptomscheker/symptomscheker.component';
import { InteractionListComponent } from './pages/interaction-list/interaction-list.component';
import { PatienttravellhistoryComponent } from './pages/patienttravellhistory/patienttravellhistory.component';
import { SymptomchekerdetailsComponent } from './pages/symptomchekerdetails/symptomchekerdetails.component';
import { WorldStatComponent } from './pages/coronaworld-stat/world-stat.component';
import { UserComponent } from './pages/user/user.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyAppComponent } from './common/privacy-policy-app/privacy-policy-app.component';
import { MalawicoronastatusComponent } from './pages/malawicoronastatus/malawicoronastatus.component';
import { TrackingHistoryComponent } from './pages/tracking-history/tracking-history.component';
import { TrackingUserHistoryComponent } from './pages/tracking-user-history/tracking-user-history.component';
import { SymptomChekerDetailsByUserIdComponent } from './pages/symptom-cheker-details-by-user-id/symptom-cheker-details-by-user-id.component';
import { DatePipe } from '@angular/common';
import { GooglemapComponent } from './pages/googlemap/googlemap.component';
import { SafePipe } from './helpers/same.pipe';
import { ManagepatientlistComponent } from './pages/managepatientlist/managepatientlist.component';
import { NewsComponent } from './pages/news/news.component';
import { VaccinesComponent } from './pages/vaccines/vaccines.component';
import { UpdatecovidnewsComponent } from './pages/updatecovidnews/updatecovidnews.component';
import { CovidlongtermeffectsComponent } from './pages/covidlongtermeffects/covidlongtermeffects.component';
import { WorldcovidstatusComponent } from './pages/worldcovidstatus/worldcovidstatus.component';
import { CovidvaccinedetailComponent } from './pages/covidvaccinedetail/covidvaccinedetail.component';
import { CovidapprovedvaccinesComponent } from './pages/covidapprovedvaccines/covidapprovedvaccines.component';
import { CovidvaccinedevelopmentComponent } from './pages/covidvaccinedevelopment/covidvaccinedevelopment.component';
import { AddsymptomcheckerComponent } from './pages/addsymptomchecker/addsymptomchecker.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { NewtrackinguserhistoryComponent } from './pages/newtrackinguserhistory/newtrackinguserhistory.component';
import { SymptomcheckerscoreComponent } from './pages/symptomcheckerscore/symptomcheckerscore.component';
import { ImportPatientComponent } from './pages/import-patient/import-patient.component';
import { CovidResultsComponent } from './pages/covid-results/covid-results.component';
import { PatientregistrationComponent } from './pages/patientregistration/patientregistration.component';
import { DoctorregistrationComponent } from './pages/doctorregistration/doctorregistration.component';
import { DigitalofficerregistrationComponent } from './pages/digitalofficerregistration/digitalofficerregistration.component';
import { DoctorSignupComponent } from './pages/doctor-signup/doctor-signup.component';
import { DigitalofficerSignupComponent } from './pages/digitalofficer-signup/digitalofficer-signup.component';
import { WhocbcComponent } from './pages/whocbc/whocbc.component';
import { CoronaAffectedareaComponent } from './pages/reports/corona-affectedarea/corona-affectedarea.component';
import { RegionofIncidentComponent } from './pages/reports/regionof-incident/regionof-incident.component';
import { HotSpotAreaComponent } from './pages/reports/hot-spot-area/hot-spot-area.component';
import { VaccineProgressComponent } from './pages/reports/vaccine-progress/vaccine-progress.component';
import { VacinprogressChartComponent } from './pages/reports/vacinprogress-chart/vacinprogress-chart.component';
import { RegionofIncidentchartComponent } from './pages/reports/regionof-incidentchart/regionof-incidentchart.component';
import { LockdownMovementComponent } from './pages/reports/lockdown-movement/lockdown-movement.component';
import { ResourcesofallocationComponent } from './pages/reports/resourcesofallocation/resourcesofallocation.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PostivityRateComponent } from './reports/postivity-rate/postivity-rate.component';
import { PostivityreportsComponent } from './reports/postivityreports/postivityreports.component';
import { TreatmentCentersComponent } from './pages/reports/subResourceallocation/treatment-centers/treatment-centers.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service


// import { AddsymptomcheckerComponent } from './pages/addsymptomchecker/addsymptomchecker.component';
// // AoT requires an exported function for factories

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppChatsModule,
    ChartsModule,
    NgChartjsModule,
    MatDialogModule,
    MatStepperModule,
    FlexLayoutModule,
    NgxDatatableModule,
    FileUploadModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatTableModule,
     MatOptionModule,
     Ng2GoogleChartsModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),

  ],
  declarations: [AppComponent, ErrorComponent, NotFoundComponent, DashboardComponent,
    TotalPatientChartComponent, NewPatientChartComponent, SuperbillSubmittedChartComponent, 
    SuperbillProcessedChartComponent, UsedLevelofcareChartComponent,
    SigninComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent,
    ProfileComponent, ChangePasswordComponent, EditProfileComponent,VaccineProgressComponent, 
    DoctorListComponent, MedicalOfficerListComponent, UserAddEditComponent, UserDetailComponent, FormDirective, CoronaIndiaStatComponent, CoronaStateStatComponent,SymptomschekerComponent,InteractionListComponent, PatienttravellhistoryComponent, SymptomchekerdetailsComponent, WorldStatComponent, UserComponent, HomeComponent, PrivacyPolicyAppComponent, MalawicoronastatusComponent, TrackingHistoryComponent, TrackingUserHistoryComponent, SymptomChekerDetailsByUserIdComponent, GooglemapComponent,SafePipe, NewsComponent,
     AddsymptomcheckerComponent,
    VaccinesComponent, UpdatecovidnewsComponent, CovidlongtermeffectsComponent, WorldcovidstatusComponent, CovidvaccinedetailComponent, CovidapprovedvaccinesComponent, CovidvaccinedevelopmentComponent,ManagepatientlistComponent,ConfirmationDialogComponent, NewtrackinguserhistoryComponent, SymptomcheckerscoreComponent, ImportPatientComponent, PatientregistrationComponent, DoctorregistrationComponent, DigitalofficerregistrationComponent,CovidResultsComponent, DoctorSignupComponent, DigitalofficerSignupComponent, CoronaAffectedareaComponent, RegionofIncidentComponent, HotSpotAreaComponent, VaccineProgressComponent, VacinprogressChartComponent, RegionofIncidentchartComponent, LockdownMovementComponent,WhocbcComponent, ResourcesofallocationComponent, AboutusComponent, PostivityRateComponent, PostivityreportsComponent, TreatmentCentersComponent],
  entryComponents: [ConfirmationDialogComponent],
  providers: [
    DatePipe,
    PatientService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    [BnNgIdleService],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }